import { GREY_600, GREY_200, GREY_150, ROBOTO, ERROR_RED } from 'lib/constants';

const actionIcon = {
  color: GREY_600,
  fontSize: '20px',
  padding: '8px 16px',
};

const gridItem = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

const text = {
  color: GREY_600,
  fontFamily: ROBOTO,
  fontSize: '15px',
  lineHeight: '250%',
};

export default {
  actionIcon,
  binId: {
    marginRight: 'auto',
  },
  editDisabled: {
    ...actionIcon,
    color: GREY_200,
  },
  gridItem,
  gridItemLeft: {
    ...gridItem,
    justifyContent: 'flex-start',
  },

  input: {
    fontFamily: ROBOTO,
    fontSize: '14px',
    maxWidth: '60px',
  },
  row: {
    alignItems: 'center',
    paddingLeft: '8px',
    '&:nth-of-type(odd)': {
      background: `${GREY_150}`,
    },
  },
  saveButton: {
    fontFamily: ROBOTO,
    fontWeight: 600,
  },
  text,
  warning: {
    ...text,
    color: ERROR_RED,
    margin: '0px 16px',
  },
};
