export const fakeProgresses = {
  '2025-01-01': [
    {
      mealLetter: 'H1',
      mealTitle: 'Hungy Man',
      goalCount: 100,
      completedCount: 50,
    },
    {
      mealLetter: 'H2',
      mealTitle: 'Double Hungy Man',
      goalCount: 200,
      completedCount: 100,
    },
    {
      mealLetter: 'H3',
      mealTitle: 'Not Hungy Enough Man',
      goalCount: 50,
      completedCount: 10,
    },
  ],
  '2025-01-07': [
    {
      mealLetter: 'H1',
      mealTitle: 'Hungy Man',
      goalCount: 100,
      completedCount: 50,
    },
    {
      mealLetter: 'H2',
      mealTitle: 'Double Hungy Man',
      goalCount: 200,
      completedCount: 100,
    },
    {
      mealLetter: 'H3',
      mealTitle: 'Not Hungy Enough Man',
      goalCount: 50,
      completedCount: 10,
    },
  ],
};
