import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import { resetStorageSlots } from 'redux/storageSlot/actions';
import {
  TabOutlineButton,
  Loading,
  DepthOptionsSelector,
} from 'components/shared';
import { usePrevious } from 'lib/custom_hooks';
import PalletDetailsButtons from 'components/WarehouseInventory/PalletDetailsButtons';

import styles from './styles';

const warningMessage =
  // eslint-disable-next-line quotes
  "This ingredient's temperature profile doesn't match the location you scanned. Are you sure you want to move this item here?";

const DepthSelection = ({
  classes,
  itemMoveRequest,
  locationReset,
  newLocationTempMatch,
  onSubmit,
  selectedDepthId,
  setSelectedDepth,
  successCallback,
  updateFailed,
  updating,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  const depthsFailed = useSelector((state) => state.storageSlots.failed);
  const depthsLoading = useSelector((state) => state.storageSlots.fetching);
  const depthOptions = useSelector((state) =>
    itemMoveRequest
      ? state.itemMoveRequests.depthOptions
      : state.pallets.inventoryApp.depthOptions
  );

  const dispatch = useDispatch();

  const onResetDepths = () => dispatch(resetStorageSlots());

  const resetDepths = useCallback(() => {
    locationReset();
    setSelectedDepth({});
    onResetDepths();
  }, [locationReset, setSelectedDepth, onResetDepths]);

  const handleDialogClose = useCallback(() => {
    setShowWarningModal(false);
  }, [setShowWarningModal]);

  const prevNewLocationTempMatch = usePrevious(newLocationTempMatch);

  useEffect(() => {
    if (!newLocationTempMatch && prevNewLocationTempMatch === null) {
      setShowWarningModal(true);
    }
  }, [newLocationTempMatch, prevNewLocationTempMatch]);

  const submitMove = () => {
    onSubmit(selectedDepthId);
  };

  const onSuccess = () => {
    resetDepths();
    successCallback();
  };

  if (depthsLoading) {
    return (
      <Fragment>
        <div className={classes.loadingContainer} data-testid="loading-icon">
          <Loading />
        </div>
      </Fragment>
    );
  } else if (depthsFailed) {
    return (
      <Fragment>
        <div className={classes.sorryContainer}>
          Sorry, no storage slot matching that location found
        </div>
        <div className={classes.centered}>
          <TabOutlineButton
            type="button"
            onClick={resetDepths}
            className={classes.button}
            data-testid="try-again-button"
          >
            Try Again
          </TabOutlineButton>
        </div>
      </Fragment>
    );
  } else if (depthOptions.length > 0) {
    return (
      <div data-testid="depth-selection">
        <DepthOptionsSelector
          selectedDepthOptionId={selectedDepthId}
          setSelectedDepthOption={setSelectedDepth}
          depthOptions={depthOptions}
        />
        <PalletDetailsButtons
          onCancelClick={resetDepths}
          onSubmitClick={submitMove}
          loading={updating}
          successCallback={onSuccess}
          failed={updateFailed}
          disabled={!selectedDepthId}
          buttonTextOverrides={{ DEFAULT: 'Save Move' }}
        />
        <Dialog
          open={showWarningModal && !itemMoveRequest}
          onClose={handleDialogClose}
        >
          <DialogContent>
            <DialogContentText>{warningMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ root: classes.modalButtons }}
              onClick={resetDepths}
            >
              Cancel
            </Button>
            <Button
              classes={{ root: classes.modalButtons }}
              onClick={handleDialogClose}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <div />;
  }
};

DepthSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  itemMoveRequest: PropTypes.bool.isRequired,
  newLocationTempMatch: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedDepthId: PropTypes.number,
  setSelectedDepth: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  updateFailed: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
};

DepthSelection.defaultProps = {
  selectedDepthId: null,
  newLocationTempMatch: null,
  successCallback: () => [],
};

export default withStyles(styles)(DepthSelection);
