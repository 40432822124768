import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI, BULK, PORTION, RECIPE } from 'lib/constants';

const getItemMoveRequests = async ({ packingFacilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

const fetchHistoricals = async ({
  packingFacilityId,
  numberRecordsRequested,
}) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests`,
      {
        status: 'completed',
        order_by: 'updated_at',
        order: 'desc',
        limit: numberRecordsRequested,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const getFromQuery = async ({ packingFacilityId, searchQuery }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests/search`,
      {
        query: searchQuery,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const fetchRecentMoveRequests = async ({ packingFacilityId }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests`,
      {
        days: 2,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const createItemMoveRequest = async ({
  packingFacilityId,
  palletId,
  newQuantity,
  fullPallet,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        move_type: 'pick_up',
        item_move_request: {
          pallet_id: palletId,
          quantity: newQuantity,
          full_pallet: fullPallet,
        },
      }),
    }
  );

  return await handleResponse(response);
};

const formatRequests = (requests, locationName, menuId, menuType, mealId) => {
  return requests.map((req) => {
    return {
      menu_id: menuId,
      menu_type: menuType,
      meal_id: mealId,
      requestable_id: req.requestableId,
      requestable_type: req.requestableType,
      location_name: locationName,
      quantity: req.requestCount,
      quantity_type: req.compoundIngredientRecipeAssignmentId
        ? RECIPE
        : PORTION,
      labeledPackaging: req.labeledPackaging,
      category: req.category || BULK,
      ingredient_assignment_id: req.ingredientAssignmentId,
      compound_ingredient_recipe_assignment_id:
        req.compoundIngredientRecipeAssignmentId,
    };
  });
};

const createItemMoveRequests = async ({
  packingFacilityId,
  requests,
  locationName,
  menuId,
  menuType,
  mealId,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        requests: formatRequests(
          requests,
          locationName,
          menuId,
          menuType,
          mealId
        ),
      }),
    }
  );

  return await handleResponse(response);
};

const putItemMoveRequest = async ({
  packingFacilityId,
  requestId,
  moveType,
  changedAttributes,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests/${requestId}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        move_type: moveType,
        item_move_request: changedAttributes,
      }),
    }
  );

  return await handleResponse(response);
};

const printChildLicensePlate = async ({
  packingFacilityId,
  requestId,
  numLabels,
  storageSlotId,
  printerId,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests/${requestId}/print_child_license_plate`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        num_labels: numLabels,
        storage_slot_id: storageSlotId,
        printer_id: printerId,
      }),
    }
  );

  return await handleResponse(response);
};

const getCaseConversion = async ({
  packingFacilityId,
  itemMoveRequestId,
  palletId,
}) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests/${itemMoveRequestId}/get_case_conversion`,
      {
        pallet_id: palletId,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const fetchSuggestedPallet = async ({ packingFacilityId, requestId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/item_move_requests/${requestId}/suggested_pallet`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

export default {
  createItemMoveRequests,
  createItemMoveRequest,
  fetchHistoricals,
  fetchRecentMoveRequests,
  getItemMoveRequests,
  putItemMoveRequest,
  getFromQuery,
  getCaseConversion,
  fetchSuggestedPallet,
  printChildLicensePlate,
};
