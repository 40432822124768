import { ROBOTO, ERROR_RED } from 'lib/constants';

export default {
  container: { padding: '12px' },
  error: {
    color: ERROR_RED,
    margin: '24px',
    fontFamily: ROBOTO,
    fontSize: '14px',
  },
  text: {
    fontWeight: '500',
    paddingBottom: '12px',
    fontFamily: ROBOTO,
  },
};
