import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import FormDrawer from 'components/RthProductionSubmissions/SubmissionForms/formComponents/FormDrawer/FormDrawer';
import { StatefulButton } from 'components/shared';
import { useSubmitImpPortions } from 'components/EcommProductionSubmissions/hooks/apiHooks';
import { SACHET_FILLER, VIBRATORY_SCALE } from 'lib/constants/index';

import styles from './styles';

const useStyles = makeStyles(styles);

const ImpSubmissionForm = ({ ingredient = {}, closeForm = () => {} }) => {
  const {
    impPortionSubmitting,
    impPortionSubmissionFailed,
    submitImpPortions,
  } = useSubmitImpPortions();

  const [numberOfPortions, setnumberOfPortions] = useState('');
  const [portioningMethod, setPortioningMethod] = useState('');

  const classes = useStyles();

  const handleToggleSelect = (_, newValue) => {
    setPortioningMethod(newValue);
  };

  const onSubmit = async () => {
    const data = {
      ingredientId: ingredient.id,
      ingredientName: ingredient.name,
      quantity: numberOfPortions,
      portionMethod: portioningMethod,
    };

    submitImpPortions(data);
  };

  const onClose = () => {
    closeForm();
    setnumberOfPortions('');
    setPortioningMethod('');
  };

  const buttonText = {
    DEFAULT: 'SUBMIT',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  return (
    <FormDrawer
      open={ingredient?.id}
      toggleOpen={onClose}
      data-testid="imp-form-drawer"
      drawerWidth="50%"
    >
      <div className={classes.ImpPortionFormDrawerContainer}>
        <div className={classes.impPortionFormDrawerTitle}>
          {ingredient.name}
        </div>
        <div className={classes.formDivider} />
        <div className={classes.fieldTitle}>Number of Portions</div>
        <TextField
          className={classes.textField}
          label="# of Portions"
          type="number"
          variant="outlined"
          value={numberOfPortions}
          onChange={(e) => setnumberOfPortions(e.target.value)}
        />
        <div className={classes.fieldTitle}>Portioning Method</div>
        <ToggleButtonGroup
          className={classes.buttonContainer}
          exclusive
          size="large"
          aria-label="Basic button group"
          value={portioningMethod}
          onChange={handleToggleSelect}
        >
          <ToggleButton
            className={classes.button}
            key="Sachet"
            value={SACHET_FILLER}
          >
            Sachet
          </ToggleButton>
          <ToggleButton
            className={classes.button}
            key="Vibratory Scale"
            value={VIBRATORY_SCALE}
          >
            Vibratory Scale
          </ToggleButton>
        </ToggleButtonGroup>
        <div>
          <StatefulButton
            className={classes.impPortionFormSubmitButton}
            type="submit"
            disabled={!numberOfPortions || !portioningMethod}
            loading={impPortionSubmitting}
            failed={impPortionSubmissionFailed}
            buttonTextOptions={buttonText}
            onClick={onSubmit}
            successCallback={onClose}
          />
        </div>
      </div>
    </FormDrawer>
  );
};

ImpSubmissionForm.propTypes = {
  ingredient: PropTypes.object.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default ImpSubmissionForm;
