import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { Snackbar } from 'components/shared';
import { getHeightColor } from 'components/WarehouseInventory/helpers';
import submitButtonTheme from 'lib/submitButtonTheme';

const PalletDetails = ({
  classes,
  guidedCount,
  pallet,
  measuringUnitsForSelectedPallet,
  openModal,
  onSubmit,
  setQuantity,
  setMeasuringUnit,
  storageSlotName,
}) => {
  const { fetching, failed } = useSelector(
    (state) => state.countListAssignment
  );

  // this value is only used for unguided counts to know when we can enable submit
  const [count, setCount] = useState('');
  const unguidedCount = !guidedCount;

  const disableSubmit = unguidedCount && !count && count !== 0;

  const onClickSubmit = (event) => {
    unguidedCount && setCount('');
    onSubmit(event);
  };

  const changeQuantity = (event) => {
    unguidedCount && setCount(event.target.value);
    setQuantity(event.target.value);
  };

  const changeUOM = (event) => {
    const id = event.target.value;
    setMeasuringUnit({
      id,
      name: measuringUnitsForSelectedPallet.find((unit) => unit.value === id)
        ?.label,
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Card
          className={classes.infoBesidesButton}
          style={{
            borderTop: `5px solid ${getHeightColor(storageSlotName)}`,
          }}
        >
          <div className={classes.topSection}>
            <div id="storageSlotName">{storageSlotName}</div>
            {!!pallet.id && (
              <IconButton
                id="editButton"
                className={classes.pencilButton}
                onClick={openModal}
                size="large"
              >
                <Edit />
              </IconButton>
            )}
          </div>
          {pallet.id ? (
            <div className={classes.bodySection}>
              <div className={classes.palletIdUnderSlot}>
                Pallet ID: {pallet.id}
              </div>
              <dl>
                <dt className={classes.infoLabel}>Item</dt>
                <dd className={classes.infoValue}>
                  {pallet.netsuiteOrderItemName}
                </dd>
                <dt className={classes.infoLabel}>Brand</dt>
                <dd className={classes.infoValue}>{pallet.brand || '-'}</dd>
              </dl>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.infoLabel}>Item ID</div>
                  <div className={classes.infoValue}>
                    {pallet.ingredientId || '-'}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={classes.infoLabel}>Product Date Code</div>
                  <div className={classes.infoValue}>
                    {pallet.productDateType}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.infoLabel}>Product Date</div>
                  <div className={classes.infoValue}>{pallet.productDate}</div>
                </Grid>
              </Grid>
              <Divider />
              <div className={classes.userInputs} key={pallet.id}>
                <TextField
                  data-testid="quantity-pallet-details"
                  label="Quantity"
                  name="quantity"
                  type="number"
                  defaultValue={guidedCount ? pallet.quantity : ''}
                  variant="outlined"
                  margin="normal"
                  onChange={changeQuantity}
                  style={{ width: '49%', margin: 'left' }}
                />
                <TextField
                  data-testid="UOM"
                  label="Unit of measure"
                  name="unit_of_measure"
                  variant="outlined"
                  margin="normal"
                  defaultValue={pallet.measuringUnitId || ''}
                  onChange={changeUOM}
                  select
                  style={{ width: '49%', margin: 'right' }}
                >
                  {measuringUnitsForSelectedPallet.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          ) : (
            <div>Location Empty</div>
          )}
        </Card>
        {!!pallet.id && (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={submitButtonTheme}>
              <Button
                type="button"
                data-testid="submit-button"
                onClick={onClickSubmit}
                disabled={disableSubmit}
              >
                CONFIRM ITEM COUNT
              </Button>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
        <Snackbar
          loading={fetching}
          failed={failed}
          messaging={{
            loading: 'Updating...',
            failure: 'Error',
            success: 'Success',
          }}
          options={{
            className: classes.snackbar,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
        />
      </div>
    </>
  );
};

PalletDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  guidedCount: PropTypes.bool,
  pallet: PropTypes.object.isRequired,
  measuringUnitsForSelectedPallet: PropTypes.array,
  openModal: PropTypes.func,
  onSubmit: PropTypes.func,
  setQuantity: PropTypes.func,
  setMeasuringUnit: PropTypes.func,
  storageSlotName: PropTypes.string.isRequired,
};

PalletDetails.defaultProps = {
  guidedCount: true,
  measuringUnitsForSelectedPallet: [],
  openModal: () => {},
  onSubmit: () => {},
  setQuantity: () => {},
  setMeasuringUnit: () => {},
};

export default PalletDetails;
