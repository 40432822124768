import { BLACK, GREY_100, GREY_200, GREY_800, ROBOTO } from 'lib/constants';

import { largeFontSize, medFontSize, smallFontSize } from '../styles';

export const subInfo = {
  color: GREY_800,
  display: 'inline-block',
  fontFamily: ROBOTO,
  fontSize: smallFontSize,
  fontWeight: 400,
  lineHeight: '20px',
  verticalAlign: 'top',
};

export default {
  actionCell: {
    verticalAlign: 'top',
    width: '25%',
  },
  collapseWrapper: {
    padding: '16px 20px 16px 20px',
  },
  detailsButton: {
    fontFamily: ROBOTO,
    fontWeight: 900,
  },
  detailsContainer: {
    backgroundColor: GREY_100,
    fontFamily: ROBOTO,
    fontSize: medFontSize,
    width: '100%',
  },
  detailsRow: {
    backgroundColor: GREY_100,
  },
  detailsRowHidden: {
    backgroundColor: GREY_100,
    display: 'none',
    transition: 'display 250ms allow-discrete',
  },
  itemContainer: {
    borderBottom: `1px solid ${GREY_200}`,
    fontSize: smallFontSize,
    padding: '10px 0px',
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  mealInfo: {
    ...subInfo,
    display: 'block',
  },
  titleCardDetailItem: {
    display: 'flex',
    paddingTop: '5px',
  },
  titleCardDetailsSection: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  subInfo,
  subTitle: {
    ...subInfo,
    color: BLACK,
    fontWeight: 900,
    minWidth: '100px',
    paddingRight: '8px',
  },
  title: {
    letterSpacing: '1px',
    fontFamily: ROBOTO,
    fontSize: largeFontSize,
    fontWeight: 900,
  },
};
