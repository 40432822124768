import {
  ROBOTO,
  WHITE,
  GREY_400,
  BLACK,
  HC_GREEN,
  DARK_HC_GREEN,
} from 'lib/constants';
import { formSubmitButton } from 'components/RthProductionSubmissions/styles';

export default {
  formDivider: {
    width: '100%',
    height: '1px',
    background: `linear-gradient(to right, ${WHITE}, 10%, ${GREY_400}, 90%, ${WHITE})`,
  },
  ImpPortionFormDrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    gap: '12px',
  },
  impPortionFormDrawerTitle: {
    fontFamily: ROBOTO,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '133.4%',
    paddingBottom: '12px',
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    gap: '10px !important',
  },
  fieldTitle: {
    fontFamily: ROBOTO,
    paddingTop: '10px',
  },
  textField: {
    fontFamily: ROBOTO,
  },
  impPortionFormSubmitButton: {
    ...formSubmitButton,
    paddingTop: '6px',
    margin: '0px',
  },
  button: {
    fontFamily: ROBOTO,
    marginLeft: 0,
    flex: '1 1 5%',
    display: 'inline-block',
    fontSize: '16px',
    borderRadius: '5px !important',
    border: '1px solid lightgrey !important',
    color: BLACK,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
};
