import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActionArea from '@mui/material/CardActionArea';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Delete from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import ContentModal from 'components/shared/ContentModal';
import { GREY_200 } from 'lib/constants';
import {
  getTimeFromDate,
  filterDateAsDDMMYY,
  dateTimeInTimeZone,
} from 'lib/utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 18,
  borderRadius: 10,
  backgroundColor: GREY_200,
}));

const CountListCard = ({ countList, deleteCountList, timeZone, history }) => {
  const classes = useStyles();
  const [deleteCountListModalOpen, setDeleteCountListModalOpen] = useState(
    false
  );

  const unguidedCountEnabled = useSelector(
    (reduxState) => reduxState.session.experiments.guided_count
  );

  const guidedCount = unguidedCountEnabled ? countList.guidedCount : true;

  const toggleDeleteModal = () =>
    setDeleteCountListModalOpen((modalOpen) => !modalOpen);

  const computePercentComplete = useMemo(() => {
    if (countList.total === 0) return 0;

    return parseInt((countList.completed / countList.total) * 100);
  });

  const goToCountList = (countListId) => {
    history.push(`/cycle-counting/${countListId}`);
  };

  const onClickConfirm = () => {
    deleteCountList(countList.id);
    toggleDeleteModal();
  };

  const formatTimeStamp = useMemo(() => {
    let date = dateTimeInTimeZone(timeZone, new Date(countList.startedAt));
    return `${getTimeFromDate(date)} ${filterDateAsDDMMYY(date)}`;
  });

  const countListLocation = countList.groundOnly
    ? countList.storageAreaName.concat(', Ground Positions')
    : countList.storageAreaName;

  const countListName = countList.name || `Count List ${countList.category}`;

  return (
    <Fragment>
      <Card className={classes.countListCard}>
        <div className={classes.cardTitle}>
          <CardActionArea
            data-testid="count-list-card"
            onClick={() => goToCountList(countList.id)}
          >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div className={classes.title}>{countListName}</div>
                <div className={classes.location}>{countListLocation}</div>
              </Grid>
              <Grid item xs={2}>
                <ChevronRight />
              </Grid>
              <Grid item xs={10}>
                <BorderLinearProgress
                  variant="determinate"
                  value={computePercentComplete}
                />
              </Grid>
              <Grid className={classes.ratio} item xs={2}>
                {countList.completed} / {countList.total}
              </Grid>
            </Grid>
          </CardActionArea>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={8} className={classes.flexRow}>
            {!guidedCount && (
              <div className={classes.guidedCount}>
                <Chip color="secondary" label="Unguided Count" />
              </div>
            )}
            {countList.users.length > 0 && (
              <div className={classes.users}>
                <Chip color="primary" label={countList.users[0]} />
                {countList.users.length > 1 && <span> + Others</span>}
              </div>
            )}
          </Grid>
          <Grid className={classes.startedAt} item xs={4}>
            {countList.startedAt ? (
              `Started ${formatTimeStamp}`
            ) : (
              <div>
                <IconButton
                  data-testid="click-delete-count-list"
                  aria-label="Delete"
                  color="inherit"
                  className={classes.trashIcon}
                  onClick={toggleDeleteModal}
                  size="large"
                >
                  <Delete /> Delete List
                </IconButton>
                <ContentModal
                  open={deleteCountListModalOpen}
                  onClose={toggleDeleteModal}
                  title="Are you sure you want to delete this list?"
                >
                  <div>
                    <div className={classes.deleteWarning}>
                      This cannot be undone
                    </div>
                    <div className={classes.submitButtons}>
                      <Button onClick={toggleDeleteModal}>Cancel</Button>
                      <Button
                        data-testid="confirm-delete-count-list"
                        aria-label="Delete"
                        color="inherit"
                        className={classes.confirmDelete}
                        onClick={onClickConfirm}
                      >
                        <Delete color="error" /> DELETE
                      </Button>
                    </div>
                  </div>
                </ContentModal>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
};

CountListCard.propTypes = {
  countList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  timeZone: PropTypes.string.isRequired,
  deleteCountList: PropTypes.func.isRequired,
};

export default CountListCard;
