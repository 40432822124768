import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import range from 'lodash/range';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import { titleize } from 'lib/utils';
import {
  WEIGHT_INPUT_DECIMAL_PLACES,
  NUMBER_WEIGHTS_REQUIRED,
  DEFAULT_WEIGHT_UOM,
} from 'lib/constants';

import {
  useValidator,
  hasListValues,
  onInputListUpdate,
} from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const WeightInputs = ({
  classes,
  weightUom = undefined,
  updateWeightUom,
  weights = [],
  updateWeights,
  validationCallback,
}) => {
  useValidator(
    hasListValues(weights, NUMBER_WEIGHTS_REQUIRED),
    validationCallback
  );

  useEffect(() => {
    if (weightUom === undefined) {
      updateWeightUom(DEFAULT_WEIGHT_UOM);
    }
  }, [weightUom, updateWeightUom]);

  const onWeightUpdate = useCallback(
    (event, index) => {
      onInputListUpdate(
        event,
        index,
        weights,
        WEIGHT_INPUT_DECIMAL_PLACES,
        NUMBER_WEIGHTS_REQUIRED,
        updateWeights
      );
    },
    [weights, updateWeights]
  );

  return (
    <div>
      <span className={classes.infoLabel}>WEIGHTS (9 Required)</span>
      <FormControl
        variant="standard"
        component="fieldset"
        className={classes.formControl}
      >
        <RadioGroup
          onChange={(e) => updateWeightUom(e.target.value)}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value={DEFAULT_WEIGHT_UOM}
            label={titleize(DEFAULT_WEIGHT_UOM)}
            classes={{ label: classes.radioButton }}
            control={
              <Radio
                checked={weightUom === DEFAULT_WEIGHT_UOM}
                color="primary"
              />
            }
          />
          <FormControlLabel
            value="grams"
            label="Grams"
            classes={{ label: classes.radioButton }}
            control={
              <Radio
                checked={weightUom !== DEFAULT_WEIGHT_UOM}
                color="primary"
              />
            }
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.weightInputsContainer}>
        {range(0, NUMBER_WEIGHTS_REQUIRED).map((index) => (
          <TextField
            classes={{ root: classes.weightInput }}
            key={index}
            type="number"
            variant="outlined"
            label={`Weight ${index + 1}`}
            inputProps={{ className: classes.inputElement }}
            value={weights[index] || ''}
            onChange={(e) => onWeightUpdate(e, index)}
          />
        ))}
      </div>
    </div>
  );
};

WeightInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  weightUom: PropTypes.string,
  updateWeightUom: PropTypes.func.isRequired,
  weights: PropTypes.arrayOf(PropTypes.string),
  updateWeights: PropTypes.func.isRequired,
  validationCallback: PropTypes.func.isRequired,
};

export default withStyles(styles)(WeightInputs);
