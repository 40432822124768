import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { submitPreliminaryCheck } from 'redux/qa/portionChecks/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import newLineButtonLeftMargin from 'lib/buttonGroupTheme';
import { BACK_BUTTON, QA_APP } from 'lib/constants';
import {
  qaStyles,
  PodLocationQuestion,
  BooleanToggleButton,
} from 'components/shared';

export const weightError = (testWeight, upperLimit, lowerLimit) => {
  // returns true (error exists) if the weight is out of upperLimit and lowerLimit range
  // returns false (error does not exists) if the weight is within acceptable range
  return !(
    testWeight === '' ||
    (testWeight <= upperLimit && testWeight >= lowerLimit)
  );
};

export const testWeightValid = (ingredient, testWeight) => {
  return (
    !weightError(
      testWeight,
      ingredient.initialTestWeights.highTolerance,
      ingredient.initialTestWeights.lowTolerance
    ) && testWeight !== ''
  );
};

const PreliminaryCheck = ({
  classes,
  onSetTopBarContent,
  meal,
  channel,
  selectedMenu,
  podLocations,
  preliminaryCheckId,
  onSubmitPreliminaryCheck,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QA_APP,
      text: `${meal.letterAssignment}: ${meal.title}`,
    });
  }, [meal.letterAssignment, meal.title, onSetTopBarContent]);

  const [selectedPod, setSelectedPod] = useState('');
  const [ingredient, setIngredient] = useState('');
  const [portionMethod, setPortionMethod] = useState('');
  const [packaging, setPackaging] = useState('');
  const [testWeight1, setTestWeight1] = useState('');
  const [testWeight2, setTestWeight2] = useState('');
  const [correctiveAction, setCorrectiveAction] = useState('');
  const [redirectToNextStep, setRedirectToNextStep] = useState(false);
  const [showCorrectiveAction, setShowCorrectiveAction] = useState(false);

  useEffect(() => {
    if (portionMethod === false || packaging === false) {
      setShowCorrectiveAction(true);
    } else {
      setShowCorrectiveAction(false);
      setCorrectiveAction('');
    }
  }, [portionMethod, packaging, correctiveAction]);

  const handleIngredient = (event, newIngredient) => {
    setIngredient(newIngredient);
  };

  const canBeSubmitted =
    !isEmpty(ingredient) &&
    typeof portionMethod === 'boolean' &&
    typeof packaging === 'boolean' &&
    !isEmpty(testWeight1) &&
    !isEmpty(testWeight2) &&
    ((showCorrectiveAction === true && correctiveAction) ||
      showCorrectiveAction === false) &&
    weightError(
      testWeight1,
      ingredient.initialTestWeights.highTolerance,
      ingredient.initialTestWeights.lowTolerance
    ) === false &&
    weightError(
      testWeight2,
      ingredient.initialTestWeights.highTolerance,
      ingredient.initialTestWeights.lowTolerance
    ) === false;

  const submitPreliminaryCheckData = () => {
    const menuId = selectedMenu.id;

    onSubmitPreliminaryCheck({
      selectedPod,
      ingredient,
      portionMethod,
      packaging,
      channel,
      menuId,
      testWeight1,
      testWeight2,
      correctiveAction,
      meal,
    });
    setRedirectToNextStep(true);
  };

  if (redirectToNextStep === true && preliminaryCheckId != null) {
    return (
      <Redirect
        to={{
          pathname: `/qa/portioning/preliminary-checks/${preliminaryCheckId}/checks`,
          state: { ingredient, meal, preliminaryCheckId },
        }}
      />
    );
  } else {
    return (
      <div className={classes.container}>
        <PodLocationQuestion
          podLocations={podLocations}
          internalLocationName={selectedPod}
          setInternalLocationName={setSelectedPod}
          questionText="Where are you performing this QA Check?"
        />

        <h3 className={classes.heading}>What ingredient is being portioned?</h3>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={newLineButtonLeftMargin}>
            <ToggleButtonGroup
              exclusive
              value={ingredient}
              onChange={handleIngredient}
              className={classes.buttonGroup}
            >
              {meal.ingredients.map((ing) => (
                <ToggleButton
                  id="ingSelectionButton"
                  data-testid="ingredient-selection-button"
                  key={`${meal.id}-${ing.name}`}
                  className={classes.ingButton}
                  value={ing}
                >
                  {ing.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            {ingredient && selectedPod && (
              <React.Fragment>
                <BooleanToggleButton
                  headerText={
                    <dt className={classes.heading}>
                      {'Is the portioning method by '}
                      <strong>{`${ingredient.portionMethod.replace(
                        /_/g,
                        ' '
                      )}`}</strong>
                      {'?'}
                    </dt>
                  }
                  value={portionMethod}
                  onChange={(_, newPortionMethod) =>
                    setPortionMethod(newPortionMethod)
                  }
                  id="portionButton"
                  data-testid="portion-button"
                />

                <BooleanToggleButton
                  headerText={
                    <dt className={classes.heading}>
                      {'Is the packaging in a '}
                      <strong>{`${ingredient.packagingType.replace(
                        /_/g,
                        ' '
                      )}`}</strong>
                      {'?'}
                    </dt>
                  }
                  value={packaging}
                  onChange={(_, newPackaging) => setPackaging(newPackaging)}
                  id="packagingButton"
                  data-testid="packaging-button"
                />

                <h3 className={classes.heading}>Initial test weights</h3>
                <h6 className={classes.weightSubHeader}>
                  {`The acceptable tolerance for ${ingredient.name} in ${
                    meal.title
                  } is: ${ingredient.initialTestWeights.lowTolerance} - ${
                    ingredient.initialTestWeights.highTolerance
                  } ${ingredient.unitOfMeasure.replace(/_/g, ' ')}`}{' '}
                </h6>
                <div className={classes.textLabelGroup}>
                  <TextField
                    error={weightError(
                      testWeight1,
                      ingredient.initialTestWeights.highTolerance,
                      ingredient.initialTestWeights.lowTolerance
                    )}
                    helperText={
                      weightError(
                        testWeight1,
                        ingredient.initialTestWeights.highTolerance,
                        ingredient.initialTestWeights.lowTolerance
                      ) === false
                        ? ''
                        : 'Weight is out of range, please correct to proceed.'
                    }
                    id="testWeight1Field"
                    data-testid="test-weight1"
                    className={classes.textField}
                    placeholder="0.0"
                    type="number"
                    variant="outlined"
                    value={testWeight1}
                    onChange={(event) => setTestWeight1(event.target.value)}
                    InputLabelProps={{
                      classes: {
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: testWeightValid(ingredient, testWeight1)
                          ? classes.cssOutlinedInput
                          : '',
                        focused: testWeightValid(ingredient, testWeight1)
                          ? classes.cssFocused
                          : '',
                        notchedOutline: testWeightValid(ingredient, testWeight1)
                          ? classes.notchedOutline
                          : '',
                      },
                      required: true,
                    }}
                  />
                  <TextField
                    error={weightError(
                      testWeight2,
                      ingredient.initialTestWeights.highTolerance,
                      ingredient.initialTestWeights.lowTolerance
                    )}
                    helperText={
                      weightError(
                        testWeight2,
                        ingredient.initialTestWeights.highTolerance,
                        ingredient.initialTestWeights.lowTolerance
                      ) === false
                        ? ''
                        : 'Weight is out of range, please correct to proceed.'
                    }
                    id="testWeight2Field"
                    data-testid="test-weight2"
                    className={classes.textField}
                    placeholder="0.0"
                    type="number"
                    variant="outlined"
                    value={testWeight2}
                    onChange={(event) => setTestWeight2(event.target.value)}
                    InputLabelProps={{
                      classes: {
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: testWeightValid(ingredient, testWeight2)
                          ? classes.cssOutlinedInput
                          : '',
                        focused: testWeightValid(ingredient, testWeight2)
                          ? classes.cssFocused
                          : '',
                        notchedOutline: testWeightValid(ingredient, testWeight2)
                          ? classes.notchedOutline
                          : '',
                      },
                      required: true,
                    }}
                  />
                </div>

                {showCorrectiveAction && (
                  <React.Fragment>
                    <h3 className={classes.heading}>
                      Corrective Actions Taken:
                    </h3>
                    <TextField
                      id="correctiveActionField"
                      fullWidth
                      multiline
                      helperText="What actions did you take to correct the problem?  Who did you notify?"
                      variant="outlined"
                      value={correctiveAction}
                      onChange={(event) =>
                        setCorrectiveAction(event.target.value)
                      }
                    />
                  </React.Fragment>
                )}

                <Button
                  id="startCheck"
                  data-testid="start-check"
                  fullWidth
                  disabled={!canBeSubmitted}
                  className={classes.startCheckButton}
                  onClick={submitPreliminaryCheckData}
                >
                  Start Check
                </Button>
              </React.Fragment>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
};

PreliminaryCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  podLocations: PropTypes.array.isRequired,
  meal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  preliminaryCheckId: state.qaPortionChecks.preliminaryCheckId,
  selectedMenu: state.menus.selectedMenu,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onSubmitPreliminaryCheck: submitPreliminaryCheck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(qaStyles)(PreliminaryCheck));
