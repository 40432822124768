import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { BACK_BUTTON, TEXT, PALLET_ACTIONS } from 'lib/constants';
import { setTopBarContent } from 'redux/topBar/actions';
import PalletFetcher from 'components/WarehouseInventory/PalletFetcher';
import { Snackbar } from 'components/shared';

import PalletDetails from './PalletDetails';

const PalletDetailsContainer = ({ match, history }) => {
  const dispatch = useDispatch();

  const onSetTopBarContent = async (args) => {
    await dispatch(setTopBarContent(args));
  };

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: TEXT,
      text: `Lot ${match.params.id}`,
      rightContent: PALLET_ACTIONS,
    });
  }, [onSetTopBarContent, match.params]);

  const { palletsPrinting, palletsPrintFailed } = useSelector(
    (state) => state.printers
  );

  const actionResult = useSelector((state) => state.storageSlots.actionResult);

  return (
    <>
      <PalletFetcher palletId={match.params.id}>
        <PalletDetails history={history} />
      </PalletFetcher>
      <Snackbar
        loading={actionResult.fetching}
        failed={actionResult.result === 'error'}
        messaging={{
          loading: 'Saving...',
          failure: `Unable to save pallet ${actionResult.id}`,
          success: 'Saved!',
        }}
      />
      <Snackbar loading={palletsPrinting} failed={palletsPrintFailed} />
    </>
  );
};

PalletDetailsContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export { PalletDetailsContainer as PalletDetailsContainerTest };

export default PalletDetailsContainer;
