export const options = {
  mode: 'cors',
  credentials: 'include', // Instructs the browser to send authentication cookies with the cross-origin request
  headers: new Headers({
    'Content-Type': 'application/json',
    accept: 'application/json',
  }),
};

export const multiPartFormOptions = {
  mode: 'cors',
  credentials: 'include',
  headers: new Headers({
    // When sending FormData, you don't need to manually set the Content-Type header b/c the
    // browser will automatically set it to multipart/form-data along with the appropriate boundary.
    // Ex: request.headers["Content-Type"] # => "multipart/form-data; boundary=----WebKitFormBoundary46kNN5VnkscHBktA"
    accept: 'application/json',
  }),
};

/**
 * Because Mealhand API sometimes returns Content-Type application/html on errors
 * and sometimes returns empty body with application/json on success or error
 */
const readResponse = async (response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const handleResponse = async (
  response,
  transformResponse = (x) => x
) => {
  let responseBody = await readResponse(response);
  if (response.ok) responseBody = transformResponse(responseBody);
  return {
    ok: response.ok,
    statusText: response.statusText,
    status: response.status,
    url: response.url,
    ...responseBody,
  };
};
