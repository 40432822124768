import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import InputLabel from '@mui/material/InputLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { CloseIcon } from 'components/icons';
import { StatefulButton, QtyTagsQuestion } from 'components/shared';
import { printChildLicensePlate } from 'redux/itemMoveRequests/actions';

import styles from './styles';

const useStyles = makeStyles(styles);

const PrinterDialog = ({ itemMoveRequestId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { printers } = useSelector((state) => state.printers);
  const {
    postingPrintChildLicensePlate,
    postingPrintChildLicensePlateFailed,
  } = useSelector((state) => state.itemMoveRequests || {});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [labelCount, setLabelCount] = useState(1);
  const [selectedPrinterId, setSelectedPrinterId] = useState();

  const resetAndClose = () => {
    setLabelCount(1);
    setSelectedPrinterId(null);
    setDialogOpen(false);
  };

  const onSubmit = () => {
    dispatch(
      printChildLicensePlate({
        requestId: itemMoveRequestId,
        numLabels: labelCount,
        printerId: selectedPrinterId,
      })
    );
  };

  return (
    <>
      <IconButton
        aria-label="Reprint Move License Plate"
        onClick={() => setDialogOpen(true)}
      >
        <PrintIcon />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={resetAndClose}
        data-testid="printer-dialog"
      >
        <div className={classes.closeButton}>
          <IconButton onClick={resetAndClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>

        <DialogTitle className={classes.header}>
          Print Child Pallet License Plate
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.form}>
            <QtyTagsQuestion
              sequenceStart={1}
              sequenceEnd={10}
              qtyTags={labelCount}
              setQtyTags={setLabelCount}
              visible
            />
            <div className={classes.printerQuestion}>
              <InputLabel className={classes.printerInputLabel}>
                Choose a Printer
              </InputLabel>
              <ToggleButtonGroup
                exclusive
                className={classes.buttonGroup}
                size="medium"
                value={selectedPrinterId}
                onChange={(_, newPrinter) => setSelectedPrinterId(newPrinter)}
                data-testid="printer-select"
              >
                {printers.map((printer) => {
                  return (
                    <ToggleButton
                      className={classes.printerButton}
                      key={printer.id}
                      value={printer.id}
                      data-testid={`printer-${printer.id}`}
                    >
                      {printer.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button className={classes.cancelButton} onClick={resetAndClose}>
            Cancel Print
          </Button>
          <StatefulButton
            loading={postingPrintChildLicensePlate}
            failed={postingPrintChildLicensePlateFailed}
            buttonTextOptions={{
              DEFAULT: 'Print',
              LOADING: 'Printing...',
              ERROR: 'Print Failed',
              SUCCESS: 'Printed!',
            }}
            type="submit"
            onClick={onSubmit}
            classes={{ root: classes.printButton }}
            disabled={!labelCount || !selectedPrinterId}
            successCallback={resetAndClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrinterDialog;
