import {
  SAPPHIRE_BLUE,
  PEACH_ORANGE,
  SUCCESS_GREEN,
  GREY_700,
  GREY_650,
} from '../../../lib/constants/styleConstants';
import { cell, header } from '../styles';

export default {
  cell,
  header,
  collapseContainer: { padding: 0, borderBottom: 0 },
  row: {
    cursor: 'pointer',
  },
  title: {
    ...header,
    fontSize: '18px',
    fontWeight: 'bolder',
    marginLeft: '2px',
    padding: '2px',
  },
  totalProgressInfoContainer: {
    width: '50%',
    padding: '10px, 16px',
  },
  totalProgressRecipeContainer: {
    width: '50%',
    padding: '10px, 16px 10px, 8px',
  },
  progressStepContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '10px',
  },
  completedContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  completedText: {
    color: GREY_700,
    fontSize: '15px',
  },
  progressBarContainer: {
    width: '58%',
    fontSize: '15px',
    marginBottom: '10px',
  },
  progressCountsContainer: {
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '66%',
  },
  progressCount: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressStepText: {
    marginBottom: '8px',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  progressStepIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    paddingRight: '5px',
  },
  progressTimeStampText: {
    color: GREY_650,
    fontSize: '14px',
    marginLeft: '2px',
    padding: '2px',
  },
  blueStepIcon: {
    color: SAPPHIRE_BLUE,
  },
  peachOrangeStepIcon: {
    color: PEACH_ORANGE,
  },
  darkGreenStepIcon: {
    color: SUCCESS_GREEN,
  },
  sapphireBlueStepIcon: {
    color: SAPPHIRE_BLUE,
  },
};
