import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import ImpSubmissions from 'components/EcommProductionSubmissions/ImpSubmissions';
import { HOME_BUTTON, ACCOUNT_DROPDOWN, TEXT } from 'lib/constants';

import PortioningLabeling from './PortioningLabeling';
import MealBagging from './MealBagging';
import BreadcrumbProvider from './BreadcrumbProvider';
import ProductionInventoryRouteLinks from './ProductionInventoryRouteLinks';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const ProductionInventory = ({
  session,
  onSetTopBarContent,
  onFetchMenus,
  onRequestPodLocations,
  onGetHoneyTagPrinters,
  menus,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: TEXT,
      rightContent: ACCOUNT_DROPDOWN,
      text: 'Production Inventory',
    });
  }, [onSetTopBarContent]);
  useEffect(() => {
    onFetchMenus();
  }, [onFetchMenus]);

  useEffect(() => {
    onGetHoneyTagPrinters();
  }, [onGetHoneyTagPrinters]);

  useEffect(() => {
    onRequestPodLocations({
      facilityId: session.packing_facility_id,
    });
  }, [onRequestPodLocations, session]);

  const firstMenuId = menus.length > 0 ? String(menus[0].id) : '';

  return (
    <BreadcrumbProvider>
      <ProductionInventoryRouteLinks
        firstMenuId={firstMenuId}
        data-testid={firstMenuId}
      />
      <Switch>
        <Route
          path={[
            '/production_inventory/labeling',
            '/production_inventory/portioning',
          ]}
          component={PortioningLabeling}
        />
        <Route
          path="/production_inventory/meal_bagging/menu"
          component={MealBagging}
        />
        <Route
          exact
          path={[
            '/production_inventory/imp_submissions',
            '/production_inventory/imp_submissions/ingredients/:ingredientId',
          ]}
          component={ImpSubmissions}
        />
      </Switch>
    </BreadcrumbProvider>
  );
};

ProductionInventory.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchMenus: PropTypes.func.isRequired,
  onRequestPodLocations: PropTypes.func.isRequired,
  onGetHoneyTagPrinters: PropTypes.func.isRequired,
  menus: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired }))
    .isRequired,
  session: PropTypes.shape({
    packing_facility_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProductionInventory;
