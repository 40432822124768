import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';
import { snakeCasedParams } from 'lib/utils';

export const getIngredients = async ({ menuId, activity }) => {
  const url = `${API_BASE_URI}/ops/production_progress/ecomm/ingredient_progress?activity=${activity}&menu_id=${menuId}`;

  const response = await fetch(url, options);
  return await handleResponse(response);
};

export const getImpIngredients = async () => {
  const url = `${API_BASE_URI}/ops/production_progress/imp/ingredient_progress`;

  const response = await fetch(url, options);
  return await handleResponse(response);
};

export const postImpPortions = async (submitParams) => {
  const url = `${API_BASE_URI}/ops/production_progress/imp/ingredient_progress`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      imp_submission: {
        ...snakeCasedParams(submitParams),
      },
    }),
  });
  return await handleResponse(response);
};
