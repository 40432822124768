import { makeApiRequest } from 'lib/api';
import {
  BOXING_LINE_ESCALATION_POST,
  BOXING_LINE_ESCALATION_PHOTO_POST,
} from 'lib/constants';

import * as boxingLineEscalation from './services';

export const createBoxingLineEscalation = (values) =>
  makeApiRequest({
    type: BOXING_LINE_ESCALATION_POST,
    method: boxingLineEscalation.postBoxingLineEscalation,
    params: values,
  });

export const postBoxingLineEscalationPhoto = (formData) =>
  makeApiRequest({
    type: BOXING_LINE_ESCALATION_PHOTO_POST,
    method: boxingLineEscalation.postBoxingLineEscalationPhoto,
    params: formData,
  });
