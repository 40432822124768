import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export const BinFormDrawer = ({
  children = null,
  open = false,
  toggleOpen,
  drawerWidth = '65%',
}) => {
  const classes = useStyles();

  const closeDrawer = () => {
    toggleOpen(false);
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      PaperProps={{ style: { width: `${drawerWidth}` } }}
    >
      <div className={classes.drawer}>
        <div className={classes.topSection}>
          <IconButton
            aria-label="close"
            onClick={closeDrawer}
            className={classes.closeButton}
            size="large"
            data-testid="bin-form-close-button"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.bodySection}>{children}</div>
      </div>
    </Drawer>
  );
};

BinFormDrawer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  drawerWidth: PropTypes.string,
  toggleOpen: PropTypes.func.isRequired,
};

export default BinFormDrawer;
