import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';

import { getMoveRequestDisplayName } from 'lib/viewHelpers';
import { getFirstAndLastInitials, msToHrMin } from 'lib/utils';
import { ListItemLink } from 'components/shared';
import { BULK, STAGING } from 'lib/constants';

import QuantityToZoneDisplay from '../QuantityToZoneDisplay/QuantityToZoneDisplay';
import PrinterDialog from '../PrinterDialog/PrinterDialog';
import styles from './styles';

const TWENTY_MINUTES = 1200000;

const ItemMoveRequest = ({
  classes,
  itemMoveRequest,
  index,
  last,
  signedInUser,
  historical = false,
}) => {
  const [elapsedTime, setElapsedTime] = useState(
    new Date() - new Date(itemMoveRequest.requestStart)
  );

  useEffect(() => {
    const updateTime = setInterval(() => {
      setElapsedTime(new Date() - new Date(itemMoveRequest.requestStart));
    }, 60000);
    return () => clearInterval(updateTime);
  }, [itemMoveRequest.requestStart]);

  const expiredTime = elapsedTime >= TWENTY_MINUTES;

  const isStagingRequest = itemMoveRequest.submitter === STAGING;

  const requestDate = (dateTime) => dateTime.split(' ')[0];

  const requestTime = (dateTime) => dateTime.split(' ').slice(1).join(' ');

  const redirectTo = () => {
    if (historical) {
      return '#';
    } else if (itemMoveRequest.pickUpTime) {
      return `/warehouse_moves/requests/in_progress/${itemMoveRequest.id}`;
    } else {
      return `/warehouse_moves/requests/open/${itemMoveRequest.id}`;
    }
  };

  return (
    <ListItemLink
      className={classes.listItemLink}
      grey={index % 2 === 0}
      white={index % 2 !== 0}
      last={last}
      showArrow={false}
      link={redirectTo()}
    >
      <div data-testid="item-move-request" className={classes.itemMoveRequest}>
        <div className={classes.leftSide}>
          {itemMoveRequest.palletId && itemMoveRequest.category === BULK && (
            <div
              className={classes.lotId}
            >{`Lot ${itemMoveRequest.palletId}`}</div>
          )}
          <div className={classes.bold}>
            <span>{getMoveRequestDisplayName(itemMoveRequest)}</span>
          </div>
          <QuantityToZoneDisplay
            itemMoveRequest={itemMoveRequest}
            sx={{ margin: '3px 0' }}
          />
        </div>
        <div className={classes.lastRow}>
          <div className={classes.leftSide}>
            <div className={classes.statusRow}>
              {!itemMoveRequest.palletId &&
                itemMoveRequest.submitter === 'Drop List' && (
                  <Chip
                    data-testid="drop-list-chip"
                    className={classes.dropListChip}
                    label="Droplist"
                  />
                )}
              {itemMoveRequest.parentId && (
                <Chip
                  className={classNames(classes.moveTypeChip, {
                    [classes.followUpChip]: !isStagingRequest,
                    [classes.stagingChip]: isStagingRequest,
                  })}
                  label={isStagingRequest ? 'Staging' : 'Follow Up'}
                />
              )}
              {!itemMoveRequest.droppedOff &&
                !itemMoveRequest.cancelled &&
                !isStagingRequest && (
                  <span
                    className={classNames(classes.timestamp, {
                      [classes.expiredTime]: expiredTime,
                    })}
                  >
                    {msToHrMin(elapsedTime)}
                  </span>
                )}
            </div>
            {itemMoveRequest.droppedOff && (
              <span className={classes.timestamp}>
                {requestDate(itemMoveRequest.formattedDropOffTime)}{' '}
                {requestTime(itemMoveRequest.formattedDropOffTime)}
                <PrinterDialog itemMoveRequestId={itemMoveRequest.id} />
              </span>
            )}
            {itemMoveRequest.cancelled && (
              <span
                className={classNames(classes.timestamp, {
                  [classes.expiredTime]: expiredTime,
                })}
              >
                Closed by{' '}
                {`${getFirstAndLastInitials(
                  itemMoveRequest.cancelledBy
                )} - ${requestDate(
                  itemMoveRequest.cancelledAtTime
                )} ${requestTime(itemMoveRequest.cancelledAtTime)}
               `}
              </span>
            )}
          </div>
          <div className={classes.rightSide}>
            {itemMoveRequest.runner && (
              <Chip
                data-testid="runner-chip"
                className={classNames(classes.chip, {
                  [classes.myChip]: itemMoveRequest.runner === signedInUser,
                })}
                label={getFirstAndLastInitials(itemMoveRequest.runner)}
              />
            )}
          </div>
        </div>
      </div>
    </ListItemLink>
  );
};

const itemMoveRequestShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  palletId: PropTypes.number,
  name: PropTypes.string.isRequired,
  quantityRequested: PropTypes.number.isRequired,
  fulfilledCases: PropTypes.number,
  pickUpLocation: PropTypes.string,
  pickedUp: PropTypes.bool.isRequired,
  droppedOff: PropTypes.bool.isRequired,
  dropOffLocation: PropTypes.string,
  requestStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
});

ItemMoveRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  itemMoveRequest: itemMoveRequestShape.isRequired,
  index: PropTypes.number.isRequired,
  last: PropTypes.bool.isRequired,
  signedInUser: PropTypes.string.isRequired,
  historical: PropTypes.bool,
};

ItemMoveRequest.defaultProps = {
  historical: false,
};

export default withStyles(styles)(ItemMoveRequest);
