import { BORDER_GREY, ROBOTO } from 'lib/constants';

import { arrowIcon, cell, cellLast, header, zebraRow } from '../styles';

export default {
  arrowIcon,
  cell,
  cellLast,
  nameAndBrand: {
    ...cell,
    display: 'flex',
    flexDirection: 'column',
  },
  ingredientAssignmentName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  ingredientAssignmentBrand: {
    fontSize: '12px',
    fontWeight: '400px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: 'rgba(0,0,0,0.6)',
  },
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
    fontFamily: ROBOTO,
  },
  header,
  zebraRow,
};
