import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';

import { getFirstAndLastInitials } from 'lib/utils';
import { PHONE_SCREEN } from 'lib/constants';

import styles from './styles';

const AccountSwitch = ({ userName = undefined, classes, onClearSession }) => {
  const smallScreen = useMediaQuery(PHONE_SCREEN);

  const handleAccountSwitch = async (e) => {
    if (e.target.value === 'signOut') {
      await onClearSession();
      window.history.replaceState({}, 'Login', '/');
    }
  };

  return (
    <form
      autoComplete="off"
      className={classes.root}
      data-testid="account-switch"
    >
      <Select
        variant="standard"
        value={userName || ''}
        onChange={handleAccountSwitch}
        disableUnderline
        inputProps={{ name: 'userName', id: 'userName' }}
        classes={{
          select: classNames(classes.accountSelect, classes.accountSelectMenu),
          icon: classes.accountIcon,
        }}
      >
        <MenuItem className={classes.menuItem} value={userName}>
          {smallScreen
            ? getFirstAndLastInitials(userName)
            : `Signed in as ${userName}`}
        </MenuItem>
        <MenuItem className={classes.menuItem} value="signOut">
          Sign Out
        </MenuItem>
      </Select>
    </form>
  );
};

AccountSwitch.propTypes = {
  userName: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onClearSession: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(AccountSwitch));
