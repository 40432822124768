import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route as ReactRouterDOMRoute, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setTopBarContent } from 'redux/topBar/actions';
import {
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
  LABELING,
  PORTIONING,
  INGREDIENT,
} from 'lib/constants';
import {
  ActivityIngredients,
  ActivityIngredientProgresses,
} from 'components/shared';

import { fakeProgresses } from './fakeProgresses';
import { useIngredients } from './hooks/apiHooks';
import BreadcrumbProvider from './BreadcrumbProvider';
import EcommActivityNavigation from './EcommActivityNavigation';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const EcommProductionSubmissions = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTopBarContent({
        leftContent: HOME_BUTTON,
        rightContent: ACCOUNT_DROPDOWN,
      })
    );
  }, []);

  const { state, actions } = useIngredients();

  const ingredientFetchCallback = (menuId, activity) => {
    actions.getByMenuAndActivity(menuId, activity);
  };

  const ingredientRequestData = {
    ingredients: state.ingredients,
    failed: state.failed,
    fetching: state.fetching,
  };

  // Note (Sam J): Fill this out with real data when the route is available.
  const activityIngredientProgressesRequestData = {
    activityIngredientProgresses: fakeProgresses,
    fetching: false,
    failed: false,
  };

  return (
    <BreadcrumbProvider>
      <Route
        path="/ecomm-production-submissions"
        render={() => <EcommActivityNavigation history={history} />}
      />
      <Route
        exact
        path={['/ecomm-production-submissions']}
        render={() => (
          <Redirect to="/ecomm-production-submissions/meal_bagging" />
        )}
      />
      <Route
        path={[
          '/ecomm-production-submissions/:activity/menu/:menuId/ingredients/:ingredientId',
        ]}
        render={() => (
          <ActivityIngredientProgresses
            requestData={activityIngredientProgressesRequestData}
            fetchCallback={() => {}}
          />
        )}
      />
      <Route
        exact
        path={[
          '/ecomm-production-submissions/labeling',
          '/ecomm-production-submissions/labeling/menu/:menuId',
        ]}
        render={() => (
          <ActivityIngredients
            activity={LABELING}
            headerText={INGREDIENT}
            fetchCallback={ingredientFetchCallback}
            requestData={ingredientRequestData}
          />
        )}
      />
      <Route
        exact
        path={[
          '/ecomm-production-submissions/portioning',
          '/ecomm-production-submissions/portioning/menu/:menuId',
        ]}
        render={() => (
          <ActivityIngredients
            activity={PORTIONING}
            headerText={INGREDIENT}
            fetchCallback={ingredientFetchCallback}
            requestData={ingredientRequestData}
          />
        )}
      />
      <Route
        exact
        path={[
          '/ecomm-production-submissions/meal_bagging',
          '/ecomm-production-submissions/meal_bagging/menu/:meal',
        ]}
        render={() => <div>Meal Bagging</div>}
      />
    </BreadcrumbProvider>
  );
};

EcommProductionSubmissions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default EcommProductionSubmissions;
