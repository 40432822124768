import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {
  ACTIVITIES_WITH_IN_PROGRESS_STATE,
  ACTIVITIES_WITH_ONLY_SUBMITTED_COUNT_IN_TABLE,
} from 'lib/constants';
import { monthSlashDay } from 'lib/utils';
import { Loading, MealsList } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

export const ActivityIngredientProgresses = ({
  fetchCallback,
  requestData,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const { activity, ingredientId, menuId } = useParams();

  const mealIds = location.state?.mealIds;

  useEffect(() => {
    if (!isEmpty(mealIds)) {
      fetchCallback({ activity, ingredientId, mealIds });
    }
  }, [activity, ingredientId, menuId]);

  if (isEmpty(mealIds)) {
    return (
      <div className={classes.error}>
        Ingredient does not have related meals.
      </div>
    );
  } else if (requestData.fetching) {
    return <Loading />;
  } else if (requestData.failed) {
    return (
      <div className={classes.error}>Failed to fetch activity progresses.</div>
    );
  } else {
    return (
      <div data-testid="compound-ingredient-activity-progresses">
        {Object.entries(requestData.activityIngredientProgresses).map(
          ([date, progresses]) => {
            return (
              <div key={date} className={classes.container}>
                <div className={classes.text}>Week {monthSlashDay(date)}</div>
                <MealsList
                  activity={activity}
                  mealProgresses={progresses}
                  showInProgress={ACTIVITIES_WITH_IN_PROGRESS_STATE.includes(
                    activity
                  )}
                  showSubmitted={ACTIVITIES_WITH_ONLY_SUBMITTED_COUNT_IN_TABLE.includes(
                    activity
                  )}
                />
              </div>
            );
          }
        )}
      </div>
    );
  }
};
