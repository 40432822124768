import React, { useEffect, useState } from 'react';

import { IngredientList, Loading } from 'components/shared';

import ImpForm from '../SubmissionForms/ImpForm';
import { useImpIngredients } from '../hooks/apiHooks';

const ImpSubmissions = () => {
  const { fetching, ingredients, getImpIngredients } = useImpIngredients();
  const [selectedIngredient, setSelectedIngredient] = useState({});

  useEffect(() => {
    getImpIngredients();
  }, []);

  const onSelectIngredient = (ingredient) => {
    setSelectedIngredient(ingredient);
  };

  const resetSelectedIngredient = () => {
    setSelectedIngredient({});
  };

  return (
    <div data-testid="imp-submissions">
      {fetching ? (
        <Loading />
      ) : (
        <IngredientList
          ingredients={ingredients}
          headerText="IMP Ingredients"
          onClick={onSelectIngredient}
        />
      )}
      <ImpForm
        ingredient={selectedIngredient}
        closeForm={resetSelectedIngredient}
      />
    </div>
  );
};

export default ImpSubmissions;
