import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TABLET_SCREEN_OR_SMALLER } from 'lib/constants';

import styles from './styles';

const PhotoTaker = ({ canvasId = 'photoTakerCanvas', onSubmit, classes }) => {
  const stream = useRef(null);
  const videoPlayer = useRef(null);
  const canvas = useRef(null);
  const tabletScreenOrSmaller = useMediaQuery(TABLET_SCREEN_OR_SMALLER);
  const [image, setImage] = useState(null);

  const viewerWidth = tabletScreenOrSmaller ? '588' : '580';
  const viewerHeight = tabletScreenOrSmaller ? '785' : '435';

  const startVideo = async () => {
    try {
      // With the user's permission, turns on camera and provides a MediaStream containing a video track with the input
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: false,
        // prefer the rear camera
        video: {
          facingMode: 'environment',
        },
      });
      // Sets video element to play the MediaStream
      videoPlayer.current.srcObject = stream.current;
      videoPlayer.current.play();
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    startVideo();
    return () => {
      // on unmount stop recording so not creepy
      stream.current && stream.current.getTracks()[0].stop();
    };
  }, []);

  const takePhoto = () => {
    const convasContext = canvas.current.getContext('2d');
    convasContext.drawImage(
      videoPlayer.current,
      0,
      0,
      viewerWidth,
      viewerHeight
    );
    setImage(canvas.current.toDataURL());
  };

  return (
    <div className={classes.root}>
      <div
        data-testid="video-photo-wrapper"
        className={classNames(classes.viewerContainer, {
          [classes.hide]: image,
        })}
      >
        {/* eslint-disable-next-line */}
        <video
          ref={videoPlayer}
          className={classes.viewer}
          width={viewerWidth}
          height={viewerHeight}
        />
        <Button
          aria-label={`${canvasId}-button`}
          className={classes.photoButton}
          variant="contained"
          color="primary"
          type="button"
          onClick={takePhoto}
          data-testid="camera-button"
        >
          <CameraIcon fontSize="large" />
        </Button>
      </div>
      <div
        data-testid="canvas-wrapper"
        className={classNames(classes.viewerContainer, classes.photoContainer, {
          [classes.hide]: !image,
        })}
      >
        <canvas
          height={viewerHeight}
          width={viewerWidth}
          className={classes.viewer}
          id={canvasId}
          ref={canvas}
        />
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            type="button"
            onClick={() => setImage(null)}
          >
            Retake
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => onSubmit(image)}
          >
            Save photo
          </Button>
        </div>
      </div>
    </div>
  );
};

PhotoTaker.propTypes = {
  canvasId: PropTypes.string, // eslint-disable-line react/require-default-props
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoTaker);
