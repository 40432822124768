import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import DepleteForm from './DepleteForm';
import UseBinForm from './UseBinForm';
import ReallocateForm from './ReallocateForm';
import WasteForm from './WasteForm';
import styles from './styles';

const useStyles = makeStyles(styles);

export const BinActions = ({ bin }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      {bin.inUse ? <DepleteForm bin={bin} /> : <UseBinForm bin={bin} />}
      <ReallocateForm bin={bin} />
      <WasteForm bin={bin} />
    </div>
  );
};

BinActions.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default BinActions;
