import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import RightIcon from '@mui/icons-material/ChevronRight';
import LeftIcon from '@mui/icons-material/ChevronLeft';
import { pdfjs, Document, Page } from 'react-pdf';

import { Loading } from 'components/shared';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SpecViewer = ({ classes, open, onCloseSpecViewer, asset }) => {
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };
  const handleBackClick = () => {
    if (pageNumber > 1) {
      setPageNumber((number) => number - 1);
    }
  };
  const handleNextClick = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber((number) => number + 1);
    }
  };
  const isPdf = asset ? asset.slice(-3) === 'pdf' : false;
  return (
    <Dialog
      open={open}
      onClose={onCloseSpecViewer}
      classes={{ paperScrollPaper: classes.dialog }}
      data-testid="spec-viewer"
    >
      <div className={classes.container}>
        {isPdf ? (
          <Fragment>
            <div className={classes.header}>
              <IconButton onClick={onCloseSpecViewer} size="large">
                <CloseIcon />
              </IconButton>
            </div>
            <div data-testid="document">
              <Document
                onLoadSuccess={handleLoadSuccess}
                file={asset}
                loading={
                  <div className={classes.errorLoading}>
                    <Loading />
                  </div>
                }
                error={
                  <div className={classes.errorLoading}>Could not load PDF</div>
                }
              >
                <Page
                  pageNumber={pageNumber}
                  width={600}
                  loading={
                    <div className={classes.errorLoading}>
                      <Loading />
                    </div>
                  }
                  error={
                    <div className={classes.errorLoading}>
                      Could not load PDF
                    </div>
                  }
                />
              </Document>
            </div>
            {numberOfPages !== null && (
              <div className={classes.bottomContent}>
                <span className={classes.buttons}>
                  <IconButton onClick={handleBackClick} size="large">
                    <LeftIcon />
                  </IconButton>
                  <IconButton onClick={handleNextClick} size="large">
                    <RightIcon />
                  </IconButton>
                </span>
                <span
                  className={classes.pageNumbers}
                >{`Page ${pageNumber} of ${numberOfPages}`}</span>
              </div>
            )}
          </Fragment>
        ) : (
          <img
            className={classes.image}
            data-testid="img-view"
            src={asset}
            alt="Failed to Load Spec Sheet"
          />
        )}
      </div>
    </Dialog>
  );
};

SpecViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  asset: PropTypes.string,
  onCloseSpecViewer: PropTypes.func.isRequired,
};

SpecViewer.defaultProps = {
  asset: null,
};

export default SpecViewer;
