import {
  GET_ECOMM_SUBMISSIONS_INGREDIENTS_REQUEST,
  GET_ECOMM_SUBMISSIONS_INGREDIENTS_SUCCESS,
  GET_ECOMM_SUBMISSIONS_INGREDIENTS_ERROR,
  SUBMIT_ECOMM_PORTIONS_REQUEST,
  SUBMIT_ECOMM_PORTIONS_SUCCESS,
  SUBMIT_ECOMM_PORTIONS_ERROR,
} from 'lib/constants';

const initialState = {
  ingredientsFetching: false,
  ingredientsFailed: false,
  ingredients: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GET_ECOMM_SUBMISSIONS_INGREDIENTS_REQUEST:
      return {
        ...state,
        ingredientsFetching: true,
        ingredientsFailed: false,
      };
    case GET_ECOMM_SUBMISSIONS_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: action.response.ingredients,
        ingredientsFetching: false,
        ingredientsFailed: false,
      };
    case GET_ECOMM_SUBMISSIONS_INGREDIENTS_ERROR:
      return {
        ...state,
        ingredientsFetching: false,
        ingredientsFailed: true,
      };
    case SUBMIT_ECOMM_PORTIONS_REQUEST:
      return {
        ...state,
        impPortionSubmitting: true,
        impPortionSubmissionFailed: false,
      };
    case SUBMIT_ECOMM_PORTIONS_SUCCESS:
      return {
        ...state,
        impPortionSubmitting: false,
        impPortionSubmissionFailed: false,
      };
    case SUBMIT_ECOMM_PORTIONS_ERROR:
      return {
        ...state,
        impPortionSubmitting: false,
        impPortionSubmissionFailed: true,
      };
    default:
      return state;
  }
}
