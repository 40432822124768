import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useActivityIngredients } from 'lib/custom_hooks';
import { MAX_EMPLOYEE_COUNT } from 'lib/constants';
import { requestPodLocations } from 'redux/podLocation/actions';
import { StatefulButton } from 'components/shared';
import {
  submitIngredientProgress,
  updateIngredientProgress,
} from 'redux/productionProgress/rth/actions';

import styles from './styles';
import FormDrawer from '../formComponents/FormDrawer';
import { PrinterContext } from '../../PrinterProvider';
import SublotCard from '../../SublotCard';

const useStyles = makeStyles(styles);

const MixingForm = ({ selectedProgress, open, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const [employeeCount, setEmployeeCount] = useState('');
  const [
    packingFacilityInternalLocationId,
    setPackingFacilityInternalLocationId,
  ] = useState('');
  const [numberOfRecipes, setNumberOfRecipes] = useState('');

  const {
    submitIngredientProgressFetching,
    submitIngredientProgressFailed,
  } = useSelector((state) => state.rthProductionProgress);

  const { packing_facility_id } = useSelector((state) => state.session);

  const { setPrinterDialogOpen, setPrintSubmission } = useContext(
    PrinterContext
  );

  useEffect(() => {
    dispatch(requestPodLocations({ facilityId: packing_facility_id }));
  }, []);

  const { pods } = useSelector((state) => state.podLocations);

  const employeeCountRange = Array.from(Array(MAX_EMPLOYEE_COUNT).keys()).map(
    (num) => num + 1
  );

  const buttonText = {
    DEFAULT: 'CONFIRM',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  const resetForm = () => {
    setNumberOfRecipes('');
    setPackingFacilityInternalLocationId('');
    setEmployeeCount('');
  };

  const onToggleOpen = () => {
    resetForm();
    toggleOpen(false);
  };

  const printLabels = (sublotId, submissionCount, isReprint = false) => {
    const printData = {
      progress: selectedProgress,
      submissionCount,
      isReprint,
      sublotId,
    };
    setPrintSubmission(printData);
    setPrinterDialogOpen(true);
  };

  const onSubmit = async () => {
    const mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

    const data = {
      progressId: selectedProgress.id,
      count: numberOfRecipes,
      packingFacilityInternalLocationId,
      employeeCount,
      mealIds,
    };

    const response = await dispatch(submitIngredientProgress(data));
    response.ok && onToggleOpen();
  };

  const onUpdate = async (data) => {
    data.progressId = selectedProgress.id;
    data.mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

    const response = await dispatch(updateIngredientProgress(data));
    response.ok && resetForm();

    return response;
  };

  return (
    <>
      <FormDrawer open={open} toggleOpen={onToggleOpen} drawerWidth="80%">
        <div className={classes.mixingDrawerContainer}>
          <div className={classes.mixingDrawerHeader}>
            <div className={classes.mixingDrawerTitle}>
              {selectedProgress.ingredientName} -{' '}
              {selectedProgress.ingredientId}
            </div>
            <div className={classes.mixingDrawerSubtitle}>
              {selectedProgress.mealLetter} - {selectedProgress.mealTitle}
            </div>
          </div>
          <div className={classes.questionContainer}>
            <div>How many recipes are you mixing?</div>
            <TextField
              label="Number of Recipes"
              type="number"
              variant="outlined"
              value={numberOfRecipes}
              onChange={(e) => setNumberOfRecipes(e.target.value)}
            />
          </div>
          <div className={classes.questionContainer}>
            <div>Where was this mixed?</div>
            <TextField
              select
              data-testid="mixing-location"
              label="Mixing Location"
              variant="outlined"
              defaultValue=""
              value={packingFacilityInternalLocationId}
              onChange={(e) =>
                setPackingFacilityInternalLocationId(e.target.value)
              }
            >
              {pods.map((pod) => (
                <MenuItem
                  id={`pod-${pod.description}`}
                  key={pod.description}
                  value={pod.id}
                >
                  {pod.description}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.questionContainer}>
            <div>How many people worked on this?</div>
            <TextField
              select
              data-testid="number-of-people"
              label="Number of People"
              variant="outlined"
              defaultValue=""
              value={employeeCount}
              onChange={(e) => setEmployeeCount(e.target.value)}
            >
              {employeeCountRange.map((num) => {
                return (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className={classes.buttonContainer}>
            <StatefulButton
              classes={{ root: classes.mixingFormSubmitButton }}
              type="submit"
              disabled={
                !employeeCount ||
                !packingFacilityInternalLocationId ||
                !numberOfRecipes
              }
              loading={submitIngredientProgressFetching}
              failed={submitIngredientProgressFailed}
              buttonTextOptions={buttonText}
              onClick={onSubmit}
              successCallback={onToggleOpen}
            />
          </div>
          {!isEmpty(selectedProgress?.completedSublots) && (
            <>
              <hr className={classes.formDivider} />
              <div className={classes.sublotDetails}>
                <div className={classes.sublotHeader}>
                  Print Labels for Completed Sublots
                </div>
                {selectedProgress.completedSublots.map((sublot) => {
                  return (
                    <div className={classes.sublot} key={sublot.id}>
                      <SublotCard
                        sublot={sublot}
                        onPrint={printLabels}
                        onUpdate={onUpdate}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </FormDrawer>
    </>
  );
};

MixingForm.propTypes = {
  selectedProgress: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default MixingForm;
