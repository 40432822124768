import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import boxingInventory from './boxingInventory/reducers';
import dockDoor from './dockDoor/reducers';
import dockScheduler from './dockScheduler/reducers';
import dropList from './dropList/reducers';
import cycleCounting from './cycleCounting/reducers';
import countListAssignment from './countListAssignment/reducers';
import ecommProductionSubmissions from './ecommProductionSubmissions/reducers';
import ingredientAssignments from './ingredientAssignments/reducers';
import ingredientInventory from './productionInventories/ingredientInventory/reducers';
import ingredients from './ingredients/reducers';
import defectsByItemCategory from './itemCategory/reducers';
import guidedPickingOverride from './guidedPickingOverride/reducers';
import guidedPutback from './guidedPutback/reducers';
import itemManager from './itemManager/reducers';
import itemMoveRequests from './itemMoveRequests/reducers';
import itemReceipt from './itemReceipt/reducers';
import labelInventory from './productionInventories/labelInventory/reducers';
import loginFields from './loginField/reducers';
import meals from './meal/reducers';
import mealBagInventory from './productionInventories/mealBagInventory/reducers';
import menuSelection from './menuSelection/reducers';
import menus from './menu/reducers';
import netsuiteOrders from './netsuiteOrders/reducers';
import netsuiteOrderItems from './netsuiteOrderItem/reducers';
import packagingItems from './packagingItems/reducers';
import printNewLabel from './boxRay/printNewLabel/reducers';
import productionSubmissions from './productionSubmission/reducers';
import podLocations from './podLocation/reducers';
import podStatuses from './podStatus/reducers';
import pallets from './pallets/reducers';
import palletHistories from './palletHistory/reducers';
import photos from './photos/reducers';
import printers from './printers/reducers';
import productionPlans from './productionPlans/reducers';
import qaMealBagChecks from './qa/mealBagChecks/reducers';
import qaPortionChecks from './qa/portionChecks/reducers';
import qaPortionMeals from './qa/portionMeals/reducers';
import rthProductionProgress from './productionProgress/rth/reducers';
import rthAssemblyProgressTracker from './rthProgressTracker/assembly/reducers';
import rthIngredientsProgressTracker from './rthProgressTracker/ingredients/reducers';
import rthMealsProgressTracker from './rthProgressTracker/meals/reducers';
import rthPortioningProgressTracker from './rthProgressTracker/portioning/reducers';
import rthSlackingProgressTracker from './rthProgressTracker/slacking/reducers';
import session from './session/reducers';
import shift from './shift/reducers';
import storageAreas from './storageArea/reducers';
import storageSlots from './storageSlot/reducers';
import topBar from './topBar/reducers';
import trucks from './truck/reducers';
import viewBoxDetails from './boxRay/viewBoxDetails/reducers';
import wmsFsqaReviews from './wmsFsqaReview/reducers';

const reducers = combineReducers({
  boxingInventory,
  countListAssignment,
  cycleCounting,
  dockDoor,
  dockScheduler,
  dropList,
  ecommProductionSubmissions,
  ingredientAssignments,
  ingredientInventory,
  ingredients,
  defectsByItemCategory,
  guidedPickingOverride,
  guidedPutback,
  itemManager,
  itemMoveRequests,
  itemReceipt,
  labelInventory,
  loginFields,
  meals,
  mealBagInventory,
  menus,
  menuSelection,
  netsuiteOrders,
  netsuiteOrderItems,
  packagingItems,
  pallets,
  palletHistories,
  printNewLabel,
  productionSubmissions,
  podLocations,
  podStatuses,
  photos,
  printers,
  productionPlans,
  qaMealBagChecks,
  qaPortionMeals,
  qaPortionChecks,
  rthProductionProgress,
  rthAssemblyProgressTracker,
  rthIngredientsProgressTracker,
  rthMealsProgressTracker,
  rthPortioningProgressTracker,
  rthSlackingProgressTracker,
  session,
  shift,
  storageAreas,
  storageSlots,
  topBar,
  trucks,
  viewBoxDetails,
  wmsFsqaReviews,
});

export default createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
