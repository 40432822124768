import { options, multiPartFormOptions, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';
import { snakeCasedParams } from 'lib/utils';

const postBoxingLineEscalation = async (form) => {
  const response = await fetch(`${API_BASE_URI}/ops/boxing_line_escalations`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ ...snakeCasedParams(form) }),
  });

  return await handleResponse(response);
};

const postBoxingLineEscalationPhoto = async (formData) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/boxing_line_escalations/photos`,
    {
      ...multiPartFormOptions,
      method: 'POST',
      body: formData,
    }
  );

  return await handleResponse(response);
};

export { postBoxingLineEscalation, postBoxingLineEscalationPhoto };
