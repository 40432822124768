import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { getHeightColor } from 'components/WarehouseInventory/helpers';

import styles from './styles';

const useStyles = makeStyles(styles);

const SlotItem = ({ selected, onSelectItem, slotItem, id, guidedCount }) => {
  const classes = useStyles();

  const { slotEmpty, inCountList, confirmed, onHold } = useMemo(
    () => ({
      slotEmpty: !slotItem.pallet.id,
      inCountList: !!slotItem.countListAssignmentId,
      confirmed: slotItem.countListAssignmentStatus === 'confirmed',
      onHold: slotItem.pallet.status === 'on hold',
    }),
    [slotItem]
  );

  const quantity = guidedCount || confirmed ? slotItem.pallet.quantity : '--';

  return (
    <CardActionArea
      id={id}
      onClick={() => onSelectItem(slotItem)}
      className={classNames(classes.pallet, {
        [classes.selected]: selected,
      })}
      style={{ borderLeft: `5px solid ${getHeightColor(slotItem.position)}` }}
    >
      <div>
        <div
          className={classNames(classes.orderName, {
            [classes.greyedOut]: !inCountList,
            [classes.bolded]: inCountList && !confirmed && !onHold,
          })}
        >
          {slotEmpty ? 'EMPTY' : `${slotItem.pallet.netsuiteOrderItemName}`}
        </div>
        {inCountList && (
          <div className={classes.orderName}>
            {quantity} @ {slotItem.pallet.measuringUnit}
          </div>
        )}
      </div>
      {confirmed && (
        <Chip
          label="COUNTED"
          size="small"
          classes={{ root: classes.primaryChip }}
          icon={<CheckCircleIcon color="primary" />}
        />
      )}
      {inCountList && onHold && (
        <Chip
          label="ON HOLD"
          size="small"
          classes={{ root: classes.secondaryChip }}
          icon={<ErrorOutlineIcon color="error" />}
        />
      )}
    </CardActionArea>
  );
};

SlotItem.defaultProps = {
  slotItem: {},
  selected: false,
};

SlotItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  slotItem: PropTypes.object,
  selected: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
  guidedCount: PropTypes.bool,
};

SlotItem.defaultProps = {
  guidedCount: true,
};

export default SlotItem;
