import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
  ASSEMBLY,
  COOKING,
  MIXING,
  CHILLING,
  SLACKING,
  RTH_PORTIONING,
} from 'lib/constants';
import AssemblyForm from 'components/RthProductionSubmissions/SubmissionForms/AssemblyForm';
import CookingForm from 'components/RthProductionSubmissions/SubmissionForms/CookingForm';
import MixingForm from 'components/RthProductionSubmissions/SubmissionForms/MixingForm';
import ChillingForm from 'components/RthProductionSubmissions/SubmissionForms/ChillingForm';
import SlackingForm from 'components/RthProductionSubmissions/SubmissionForms/SlackingForm';
import PortioningForm from 'components/RthProductionSubmissions/SubmissionForms/PortioningForm';

import styles from './styles';

const useStyles = makeStyles(styles);

const MealsList = ({
  activity,
  mealProgresses = [],
  showInProgress = false,
  showSubmitted = false,
}) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState({});

  useEffect(() => {
    const currentProgress = mealProgresses.find(
      (progress) => progress.id === selectedProgress.id
    );

    currentProgress && setSelectedProgress(currentProgress);
  }, [mealProgresses]);

  const onClickRow = (progress) => {
    setSelectedProgress(progress);
    setDrawerOpen(true);
  };

  const renderForm = () => {
    const sharedFormProps = {
      open: drawerOpen,
      toggleOpen: setDrawerOpen,
      selectedProgress,
    };

    switch (activity) {
      case ASSEMBLY:
        return <AssemblyForm {...sharedFormProps} />;
      case COOKING:
        return <CookingForm {...sharedFormProps} />;
      case MIXING:
        return <MixingForm {...sharedFormProps} />;
      case CHILLING:
        return <ChillingForm {...sharedFormProps} />;
      case SLACKING:
        return <SlackingForm {...sharedFormProps} />;
      case RTH_PORTIONING:
        return <PortioningForm {...sharedFormProps} />;
      // When forms are built for ecomm in OPS-7275, go ahead and handle those here
    }
  };

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>ID</TableCell>
              <TableCell className={classes.header}>Meal Name</TableCell>
              <>
                <TableCell className={classes.header}>Required</TableCell>
                {showInProgress && (
                  <TableCell className={classes.header}>
                    In&nbsp;Progress
                  </TableCell>
                )}
                <TableCell className={classes.header}>
                  {showSubmitted ? 'Submitted' : 'Completed'}
                </TableCell>
                <TableCell className={classes.header}>Remaining</TableCell>
              </>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classes.clickableRows}>
            {mealProgresses.map((progress) => (
              <TableRow
                key={progress.id}
                className={classes.zebraRow}
                onClick={() => onClickRow(progress)}
              >
                <TableCell
                  className={classes.mealLetter}
                  component="th"
                  scope="row"
                >
                  {progress.mealLetter}
                </TableCell>
                <TableCell className={classes.cell}>
                  {progress.mealTitle}
                </TableCell>
                <>
                  <TableCell className={classes.cell}>
                    {progress.goalCount}
                  </TableCell>
                  {showInProgress && (
                    <TableCell className={classes.cell}>
                      {progress.openCount}
                    </TableCell>
                  )}
                  <TableCell className={classes.cell}>
                    {progress.completedCount}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {progress.goalCount - progress.completedCount}
                  </TableCell>
                </>
                <TableCell className={classes.cellLast}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderForm()}
    </>
  );
};

MealsList.propTypes = {
  activity: PropTypes.oneOf([
    ASSEMBLY,
    COOKING,
    MIXING,
    CHILLING,
    SLACKING,
    RTH_PORTIONING,
  ]),
  mealProgresses: PropTypes.array,
  showInProgress: PropTypes.bool,
  showSubmitted: PropTypes.bool,
};

export default MealsList;
