import React, { Fragment, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { useHistory } from 'react-router-dom';

import { getLicensePlatePrinters } from 'redux/printers/actions';
import { sortHistoricalDates } from 'components/WarehouseMoves/helpers';
import { Loading, PalletScanTextField } from 'components/shared';
import {
  fetchItemPutbacks,
  resetParentPallet,
} from 'redux/guidedPutback/actions';

import styles from '../InProgressRequests/styles';
import ItemPutbackRow from './ItemPutbackRow';

const useStyles = makeStyles(styles);

const ItemPutbackQueue = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [numberOfHistoricals, setNumberOfHistoricals] = useState(10);

  let { user_name } = useSelector((state) => state.session);
  let { itemPutbacksRequestStatus } = useSelector(
    (state) => state.guidedPutback
  );

  const groupByStatus = (putback) =>
    putback.droppedOff || putback.cancelled ? 'completed' : 'inProgress';

  const { inProgress, completed } = useSelector((state) =>
    groupBy(state.guidedPutback.itemPutbacks, groupByStatus)
  );

  let signedInUser = user_name;

  useEffect(() => {
    dispatch(fetchItemPutbacks());
    dispatch(resetParentPallet());
    dispatch(getLicensePlatePrinters());
  }, []);

  const processPalletScan = (palletId) => {
    history.push(`/warehouse_moves/putbacks/new/${palletId}`);
  };

  if (itemPutbacksRequestStatus.fetching) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  }

  return (
    <Fragment>
      <PalletScanTextField canScan onScan={processPalletScan} />

      <div>
        {inProgress?.length > 0 ? (
          inProgress.map((itemPutback, index) => (
            <ItemPutbackRow
              key={itemPutback.id}
              index={index}
              itemPutback={itemPutback}
              last={index === inProgress.length - 1}
              signedInUser={signedInUser}
            />
          ))
        ) : (
          <div className={classes.noRequestsMessage}>
            There are currently no new putbacks
          </div>
        )}
      </div>
      <div className={classes.subheader}>Historical Requests</div>
      {completed?.length > 0 ? (
        completed
          .sort(sortHistoricalDates)
          .slice(0, numberOfHistoricals)
          .map((itemPutback, index) => (
            <ItemPutbackRow
              itemPutback={itemPutback}
              index={index}
              key={itemPutback.id}
              last={index === completed.length - 1}
              signedInUser={signedInUser}
              historical
            />
          ))
      ) : (
        <div className={classes.nothingMessage}>
          There are currently no historical putbacks
        </div>
      )}
    </Fragment>
  );
};

export default ItemPutbackQueue;
