export * from './keyConstants';
export * from './reduxConstants';
export * from './styleConstants';
export * from './topBarConstants';
export * from './warehouseConstants';
export * from './routingConstants';
export * from './itemManagerConstants';
export * from './productionProgress/rth';
export * from './progressTracker';

export const CHICAGO_FACILITY_ID = 1;
export const SB_FACILITY_ID = 2;
export const ATLANTA_FACILITY_ID = 3;
export const SKYVIEW_FACILITY_ID = 7;
export const BAL_FACILITY_ID = 8;

export const RETAIL = 'retail';
export const STANDARD_PLAN = 'standard';
export const FRESH_AND_EASY = 'fresh-and-easy';

export const RETAIL_CHANNEL = RETAIL;
export const ECOMMERCE_CHANNEL = 'ecommerce';

// Channels as listed in the Database
export const ECOMMERCE_DB = 'eCommerce';
export const RETAIL_DB = 'Retail';

// Activities
export const LABELING = 'labeling';
export const PORTIONING = 'portioning';
export const MEAL_BAGGING = 'meal_bagging';
export const CUSTOMIZE_IT_PORTIONING = 'customize_it_portioning';

// Portion and Labeling Methods
export const APPLICATOR = 'applicator';
export const BY_HAND = 'by hand';
export const BY_MACHINE = 'by_machine';
export const NO_PORTION_METHOD = 'no portion method';

export const API_BASE_URI = process.env.REACT_APP_API_URI;
export const REACT_APP_PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const REACT_APP_SENTRY_TRACES_SAMPLE_RATE =
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;

export const PORTIONERS = [
  'PS 125',
  'Portion Filler',
  'Sprinter',
  'Vibratory Scale',
  'Liquid Filling Line',
  'Combo Scale',
  'Semi-Automatic Combo Scale',
  'Sachet Filler',
  'Other',
];

export const SACHET_FILLER = 'sachet_filler';
export const VIBRATORY_SCALE = 'vibratory_scale';

export const PORTION_FILLER_COUNT_BY_FACILITY_ID = {
  1: 10,
  2: 15,
  3: 5,
};

export const CYCLE_COUNT = 'Cycle Count';

// This is used to make sure that ops users are logged out on close of the browser
export const ACTIVE_OPS_SESSION = 'active_ops_session';

export const NON_PROP_12 = '[non-prop12]';
