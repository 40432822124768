import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchIngredients,
  fetchImpIngredients,
  createImpPortions,
} from 'redux/ecommProductionSubmissions/actions';

export const useIngredients = () => {
  const dispatch = useDispatch();

  const { ingredients, ingredientsFetching, ingredientsFailed } = useSelector(
    (state) => state.ecommProductionSubmissions
  );

  const getByMenuAndActivity = async (menuId, activity) => {
    await dispatch(fetchIngredients(menuId, activity));
  };

  return {
    state: {
      fetching: ingredientsFetching,
      failed: ingredientsFailed,
      ingredients,
    },
    actions: {
      getByMenuAndActivity,
    },
  };
};

export const useImpIngredients = () => {
  const dispatch = useDispatch();
  const [ingredients, setIngredients] = useState([]);
  const [fetching, setIngredientsFetching] = useState(false);
  const [failed, setIngredientsFailed] = useState(false);

  const getImpIngredients = async () => {
    setIngredientsFetching(true);
    const response = await dispatch(fetchImpIngredients());

    if (response.ok) {
      setIngredients(response.ingredients);
    } else {
      setIngredientsFailed(true);
    }

    setIngredientsFetching(false);
  };

  return {
    fetching,
    failed,
    ingredients,
    getImpIngredients,
  };
};

export const useSubmitImpPortions = () => {
  const dispatch = useDispatch();

  const { impPortionSubmitting, impPortionSubmissionFailed } = useSelector(
    (state) => state.ecommProductionSubmissions
  );

  const submitImpPortions = async (data) => {
    await dispatch(createImpPortions(data));
  };

  return {
    impPortionSubmitting,
    impPortionSubmissionFailed,
    submitImpPortions,
  };
};
