import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';

import styles from '../styles';

const useStyles = makeStyles(styles);

export const BinDetails = ({ bin }) => {
  const classes = useStyles();

  const titlesAndValues = [
    [
      {
        title: 'Sublot ID',
        value: bin.sublotId,
      },
      {
        title: 'Sublot Activity',
        value: bin.sublotActivity,
      },
    ],
    [
      {
        title: 'Ingredient ID',
        value: bin.ingredientId,
      },
      {
        title: 'Ingredient Name',
        value: bin.ingredientName,
      },
    ],
    [
      {
        title: 'Depleted At',
        value: bin.depletedAt || 'N/A',
      },
      {
        title: 'Wasted At',
        value: bin.wastedAt || 'N/A',
      },
    ],
  ];

  const renderDetailSet = (set) => {
    return (
      <div className={classes.itemContainer} key={uuidv4()}>
        {set.map((item) => (
          <div key={item.title}>
            <div className={classes.subTitle}>{item.title}:</div>{' '}
            <div className={classes.subInfo}>{item.value}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderOtherMeals = () => {
    const meals = bin.relatedMealsForIngredientForShipweek;

    return (
      <div className={classes.itemContainer}>
        <div className={classes.subTitle}>Compatible Meals:</div>
        <div className={classes.subInfo}>
          {isEmpty(meals) ? (
            <div className={classes.mealIinfo}>N/A</div>
          ) : (
            meals.map((meal) => (
              <div className={classes.mealInfo} key={uuidv4()}>
                {meal.mealLetterAssignment} - {meal.mealName}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.detailsContainer}>
      {titlesAndValues.map((set) => renderDetailSet(set))}
      {renderOtherMeals()}
    </div>
  );
};

BinDetails.propTypes = {
  bin: PropTypes.object.isRequired,
};
