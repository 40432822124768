import {
  GET_ACTIVITY_INGREDIENTS_REQUEST,
  GET_ACTIVITY_INGREDIENTS_SUCCESS,
  GET_ACTIVITY_INGREDIENTS_ERROR,
  GET_ACTIVITY_INGREDIENT_PROGRESSES_REQUEST,
  GET_ACTIVITY_INGREDIENT_PROGRESSES_SUCCESS,
  GET_ACTIVITY_INGREDIENT_PROGRESSES_ERROR,
  GET_ACTIVITY_INGREDIENT_PROGRESSES_SILENT_SUCCESS,
  GET_MEAL_PROGRESSES_REQUEST,
  GET_MEAL_PROGRESSES_SUCCESS,
  GET_MEAL_PROGRESSES_ERROR,
  SUBMIT_MEAL_PROGRESS_REQUEST,
  SUBMIT_MEAL_PROGRESS_SUCCESS,
  SUBMIT_MEAL_PROGRESS_ERROR,
  SUBMIT_INGREDIENT_PROGRESS_REQUEST,
  SUBMIT_INGREDIENT_PROGRESS_SUCCESS,
  SUBMIT_INGREDIENT_PROGRESS_ERROR,
  PRINT_RTH_LABELS_REQUEST,
  PRINT_RTH_LABELS_SUCCESS,
  PRINT_RTH_LABELS_ERROR,
  UPDATE_INGREDIENT_PROGRESS_REQUEST,
  UPDATE_INGREDIENT_PROGRESS_SUCCESS,
  UPDATE_INGREDIENT_PROGRESS_ERROR,
} from 'lib/constants';

const initialState = {
  activityIngredients: [],
  activityIngredientsFetching: false,
  activityIngredientsFailed: false,
  activityIngredientProgresses: {},
  activityIngredientProgressesFetching: false,
  activityIngredientProgressesFailed: false,
  activity: {},
  mealProgresses: [],
  mealProgressesFetching: false,
  mealProgressesFailed: false,
  submitMealProgressFetching: false,
  submitMealProgressFailed: false,
  submitIngredientProgressFetching: false,
  submitIngredientProgressFailed: false,
  printLabelsFetching: false,
  printLabelsFailed: false,
  updateIngredientProgressFetching: false,
  updateIngredientProgressFailed: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GET_ACTIVITY_INGREDIENTS_REQUEST:
      return {
        ...state,
        activityIngredientsFailed: false,
        activityIngredientsFetching: true,
      };
    case GET_ACTIVITY_INGREDIENTS_SUCCESS:
      return {
        ...state,
        activityIngredients: action.response.ingredients,
        activityIngredientsFailed: false,
        activityIngredientsFetching: false,
      };
    case GET_ACTIVITY_INGREDIENTS_ERROR:
      return {
        ...state,
        activityIngredientsFetching: false,
        activityIngredientsFailed: true,
      };
    case GET_ACTIVITY_INGREDIENT_PROGRESSES_REQUEST:
      return {
        ...state,
        activityIngredientProgressesFailed: false,
        activityIngredientProgressesFetching: true,
      };
    case GET_ACTIVITY_INGREDIENT_PROGRESSES_SUCCESS:
      return {
        ...state,
        activityIngredientProgresses: action.response.ingredient_progresses,
        activityIngredientProgressesFailed: false,
        activityIngredientProgressesFetching: false,
      };
    case GET_ACTIVITY_INGREDIENT_PROGRESSES_ERROR:
      return {
        ...state,
        activityIngredientProgressesFetching: false,
        activityIngredientProgressesFailed: true,
      };
    case GET_ACTIVITY_INGREDIENT_PROGRESSES_SILENT_SUCCESS:
      return {
        ...state,
        activityIngredientProgresses: action.response.ingredient_progresses,
      };
    case GET_MEAL_PROGRESSES_REQUEST:
      return {
        ...state,
        mealProgressesFailed: false,
        mealProgressesFetching: true,
      };
    case GET_MEAL_PROGRESSES_SUCCESS:
      return {
        ...state,
        mealProgressesFailed: false,
        mealProgressesFetching: false,
        mealProgresses: action.response.meal_progresses,
      };
    case GET_MEAL_PROGRESSES_ERROR:
      return {
        ...state,
        mealProgressesFetching: false,
        mealProgressesFailed: true,
      };
    case SUBMIT_MEAL_PROGRESS_REQUEST:
      return {
        ...state,
        submitMealProgressFailed: false,
        submitMealProgressFetching: true,
      };
    case SUBMIT_MEAL_PROGRESS_SUCCESS:
      return {
        ...state,
        submitMealProgressFailed: false,
        submitMealProgressFetching: false,
        mealProgresses: action.response.meal_progresses,
        activity: action.response.activity,
      };
    case SUBMIT_MEAL_PROGRESS_ERROR:
      return {
        ...state,
        submitMealProgressFetching: false,
        submitMealProgressFailed: true,
      };
    case SUBMIT_INGREDIENT_PROGRESS_REQUEST:
      return {
        ...state,
        submitIngredientProgressFailed: false,
        submitIngredientProgressFetching: true,
      };
    case SUBMIT_INGREDIENT_PROGRESS_SUCCESS:
      return {
        ...state,
        submitIngredientProgressFailed: false,
        submitIngredientProgressFetching: false,
        activityIngredientProgresses: action.response.ingredient_progresses,
        activity: action.response.activity,
      };
    case SUBMIT_INGREDIENT_PROGRESS_ERROR:
      return {
        ...state,
        submitIngredientProgressFailed: true,
        submitIngredientProgressFetching: false,
      };
    case UPDATE_INGREDIENT_PROGRESS_REQUEST:
      return {
        ...state,
        updateIngredientProgressFetching: true,
        updateIngredientProgressFailed: false,
      };
    case UPDATE_INGREDIENT_PROGRESS_SUCCESS:
      return {
        ...state,
        updateIngredientProgressFetching: false,
        updateIngredientProgressFailed: false,
        activityIngredientProgresses: action.response.ingredient_progresses,
        activity: action.response.activity,
      };
    case UPDATE_INGREDIENT_PROGRESS_ERROR:
      return {
        ...state,
        updateIngredientProgressFetching: false,
        updateIngredientProgressFailed: true,
      };
    case PRINT_RTH_LABELS_REQUEST:
      return {
        ...state,
        printLabelsFailed: false,
        printLabelsFetching: true,
      };
    case PRINT_RTH_LABELS_SUCCESS:
      return {
        ...state,
        printLabelsFailed: false,
        printLabelsFetching: false,
      };
    case PRINT_RTH_LABELS_ERROR:
      return {
        ...state,
        printLabelsFailed: true,
        printLabelsFetching: false,
      };
    default:
      return state;
  }
}
