import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { LABELING, PORTIONING, MEAL_BAGGING } from 'lib/constants';
import { Breadcrumbs } from 'components/shared';

import { BreadcrumbContext } from '../BreadcrumbProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const EcommActivityNavigation = ({ history }) => {
  const classes = useStyles();
  const breadcrumbs = useContext(BreadcrumbContext);

  if (breadcrumbs.length) {
    return (
      <div className={classes.breadcrumbContainer}>
        <Breadcrumbs backButton context={BreadcrumbContext} history={history} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.linkContainer}>
        <NavLink
          to={`/ecomm-production-submissions/${LABELING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {LABELING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/ecomm-production-submissions/${PORTIONING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {PORTIONING.toUpperCase()}
        </NavLink>
        <NavLink
          to={`/ecomm-production-submissions/${MEAL_BAGGING}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {MEAL_BAGGING.toUpperCase()}
        </NavLink>
      </div>
    </>
  );
};

export default EcommActivityNavigation;
