import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const FormDrawer = ({
  children = null,
  open = false,
  toggleOpen,
  drawerWidth = '25%',
}) => {
  const classes = useStyles();

  const closeDrawer = () => {
    toggleOpen(false);
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      data-testid="form-drawer"
      PaperProps={{ style: { width: `${drawerWidth}` } }}
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          <TabOutlineButton
            className={classes.closeButton}
            type="button"
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.icon} />
          </TabOutlineButton>
        </div>
        <div className={classes.bodySection}>{children}</div>
      </div>
    </Drawer>
  );
};

FormDrawer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  drawerWidth: PropTypes.string,
  toggleOpen: PropTypes.func.isRequired,
};

export default FormDrawer;
