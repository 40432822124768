import { makeApiRequest } from 'lib/api';
import {
  GET_ECOMM_SUBMISSIONS_INGREDIENTS,
  SUBMIT_ECOMM_PORTIONS,
} from 'lib/constants';

import { getIngredients, getImpIngredients, postImpPortions } from './services';

export const fetchIngredients = (menuId, activity) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: GET_ECOMM_SUBMISSIONS_INGREDIENTS,
      method: getIngredients,
      params: {
        menuId,
        activity,
      },
    })
  );
};

export const fetchImpIngredients = () => {
  return makeApiRequest({
    method: getImpIngredients,
  });
};

export const createImpPortions = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: SUBMIT_ECOMM_PORTIONS,
      method: postImpPortions,
      params,
    })
  );
};
