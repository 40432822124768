import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';

import { getHeightColor } from 'components/WarehouseInventory/helpers';
import { Loading } from 'components/shared';

import QuantityToZoneDisplay from '../../QuantityToZoneDisplay';
import styles from '../styles';

const useStyles = makeStyles(styles);

const SuggestedPalletInfo = ({
  suggestedPallet,
  itemMoveRequest,
  showLastLocation,
  fetching,
}) => {
  const classes = useStyles();

  const hasSuggestedPallet = suggestedPallet?.id;
  const hasItemMoveRequest = itemMoveRequest?.id;
  const assignedLocation = itemMoveRequest?.pickUpLocation || '';

  const bubbleColor = useMemo(() => getHeightColor(suggestedPallet?.location), [
    suggestedPallet,
  ]);

  const last = showLastLocation ? 'Last ' : '';
  const location = showLastLocation
    ? suggestedPallet?.lastLocation
    : suggestedPallet?.location;

  const daysUntilExpirationIcon = (daysUntilExpiration) => {
    if (daysUntilExpiration <= 0)
      return <ReportIcon className={classes.expired} />;
    if (daysUntilExpiration > 0 && daysUntilExpiration <= 7)
      return <WarningIcon className={classes.warning} />;
  };

  return (
    <div className={classes.palletDetailsContainer}>
      {fetching ? (
        <Loading />
      ) : (
        <div data-testid="suggested-pallet-info" className={classes.text}>
          <div className={classes.location}>
            <span className={classes.boldText}>{last}Location:&nbsp;</span>
            {assignedLocation || hasSuggestedPallet ? (
              <>
                <div
                  style={{ backgroundColor: bubbleColor }}
                  className={classes.locationColor}
                />
                {assignedLocation || location}
              </>
            ) : (
              'No viable pallet'
            )}
          </div>
          {hasSuggestedPallet && (
            <div className={classes.suggestedPalletInfoContainer}>
              <div className={classes.suggestedPalletInfoItemContainer}>
                <span className={classes.suggestedPalletInfoItemLabel}>
                  Pallet
                </span>
                <span>{suggestedPallet.id}</span>
              </div>
              <div className={classes.suggestedPalletInfoItemContainer}>
                <span className={classes.suggestedPalletInfoItemLabel}>
                  Days Until Expiration
                </span>
                <span>{suggestedPallet.daysUntilExpiration}</span>
                {daysUntilExpirationIcon(suggestedPallet.daysUntilExpiration)}
              </div>
            </div>
          )}
          {!hasItemMoveRequest && hasSuggestedPallet && (
            <div>
              {`${suggestedPallet.productDateType}, ${suggestedPallet.productDate}`}
            </div>
          )}
        </div>
      )}
      {hasItemMoveRequest && (
        <QuantityToZoneDisplay itemMoveRequest={itemMoveRequest} />
      )}
    </div>
  );
};

SuggestedPalletInfo.propTypes = {
  itemMoveRequest: PropTypes.object,
  suggestedPallet: PropTypes.shape({
    id: PropTypes.number,
    storageArea: PropTypes.string,
  }),
  showLastLocation: PropTypes.bool,
  fetching: PropTypes.bool,
};

SuggestedPalletInfo.defaultProps = {
  suggestedPallet: {
    id: null,
    storageArea: '',
  },
  itemMoveRequest: {},
  showLastLocation: false,
  fetching: false,
};

export default SuggestedPalletInfo;
