import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { formatItemId } from '../CycleCountingList/stateUtils';
import styles from './styles';
import SlotItem from './SlotItem';

const useStyles = makeStyles(styles);

const StorageSlotGroups = ({
  groupedSlots,
  selectedItemId,
  selectStorageSlot,
  guidedCount,
}) => {
  const classes = useStyles();

  return groupedSlots.map((group) => (
    <div key={group.name}>
      <div className={classes.slotGroup}>{group.name}</div>
      {group.slots.map((slotItem) => {
        const slotItemId = formatItemId({
          slotName: slotItem.storageSlotName,
          palletId: slotItem.pallet.id,
        });
        return (
          <SlotItem
            key={slotItemId}
            id={slotItemId} // using this id to auto scroll to an item
            selected={selectedItemId === slotItemId}
            guidedCount={guidedCount}
            slotItem={slotItem}
            onSelectItem={selectStorageSlot}
          />
        );
      })}
    </div>
  ));
};

StorageSlotGroups.propTypes = {
  groupedSlots: PropTypes.array.isRequired,
  selectStorageSlot: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string.isRequired,
  guidedCount: PropTypes.bool,
};

StorageSlotGroups.defaultProps = {
  guidedCount: true,
};

export default StorageSlotGroups;
